import axios from "axios";
import { useEffect, useState } from "react";
import { Input } from "../../../components/Input";
import { Wizard } from "../../../components/Wizard";
import { maskCepInput, maskPhoneInput } from "../../../data/formatting/document-formats/document-formats";
import { NUMBER_ONLY } from "../../../data/regex";
import { Container, Line, Empty, Genero, Controllers } from "./styles";
import { ModalDefault } from "../../../components/ModalDefault";
import { Autocomplete } from "../../../components/Autocomplete";

export function Address(props) {
    const {
        cep,
        address,
        number,
        complement,
        city,
        state,
        primaryPhone,
        secondaryPhone,
        changeStep,
        save,
        country,
        foreigner
    } = props

    const isFormValid = [
        cep,
        address,
        number,
        city,
        state,
        primaryPhone,
    ].filter((item) => `${item}`.length === 0).length === 0

    const [cities, setCities] = useState([])

    const [targetField, setTargetField] = useState('cep')
    const fetchForSelectedStateCities = async () => {
        try {
            //here we retrieve the list of cities from IBGE API
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
            if (response.status === 200) {
                const cities = Array.from(response.data || []).map(({ nome }) => nome)
                setCities(cities)
            }

        } catch (error) {
            console.log(error, 'error')
        }
    }

    const remainingFields = [
        {
            value: cep,
            label: 'cep',
            message: 'O campo CEP é obrigatório'
        },
        {
            value: address,
            label: 'address',
            message: 'O campo endereço é obrigatório'
        },
        {
            value: number,
            label: 'number',
            message: 'O campo número é obrigatório'
        },
        {
            value: state,
            label: 'state',
            message: 'O campo estado é obrigatório'
        },
        {
            value: city,
            label: 'city',
            message: 'O campo cidade é obrigatório'
        },
        {
            value: primaryPhone,
            label: 'primaryPhone',
            message: 'O campo telefone é obrigatório'
        },
    ].filter(({ value }) => `${value}`.length === 0)

    const isAltPhoneValid = secondaryPhone.replace(NUMBER_ONLY, "").length === 0 ||
        secondaryPhone.replace(NUMBER_ONLY, "").length > 9

    useEffect(() => {
        if (state.length > 0 && !foreigner) fetchForSelectedStateCities()
    }, [state])

    const isZipcodeValid = cep.replace(NUMBER_ONLY, "").length === 8

    const isPhoneValid = primaryPhone.replace(NUMBER_ONLY, "").length > 9

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const [drowdownCities, setDropdownCities] = useState([]);


    const validationStatuses = {
        cep: {
            label: 'cep',
            message: 'CEP inválido'
        },
        phone: {
            label: 'primaryPhone',
            message: 'Telefone principal inválido'
        },
        altPhone: {
            label: 'secondaryPhone',
            message: 'Telefone secundário inválido'
        }
    }

    const focusOnLastRequiredField = () => {

        const getFirstInvalidField = remainingFields[0]

        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
        setTargetField(getFirstInvalidField.label)
    }
    const closeModalShowLastRequiredField = () => {

        if (remainingFields.length > 0) {
            const getFirstInvalidField = remainingFields[0]
            if (getFirstInvalidField.label === targetField) {
                setTargetField(' ')
                setModalError(false)
                const timedOutSetField = setTimeout(() =>
                    setTargetField(getFirstInvalidField.label), 256)
                return () => clearTimeout(timedOutSetField)
            }
            setTargetField(getFirstInvalidField.label)
            return
        }
        const selectedField = !isZipcodeValid ? 'cep' : !isPhoneValid ? 'phone'
            : !isAltPhoneValid ? 'altPhone' : ' '
        const getInvalidField = validationStatuses[selectedField]

        setModalError(false)
        if(getInvalidField.label === targetField) {
            setTargetField(' ')
            const timedOutSetField = setTimeout(() => 
            setTargetField(getInvalidField.label), 256)
            return () => clearTimeout(timedOutSetField)
        }
        setTargetField(getInvalidField.label);
    }
    function handleCityInput (input) {
        props.setCity(input)
        if (cities.length > 0) {
            const variableToSearch = input.toLocaleLowerCase();

            const citiesFiltered = cities.filter(element => {
                return element
                    .toLocaleLowerCase()
                    .includes(variableToSearch)
            });

            setDropdownCities(citiesFiltered)
        }
    }

    function handleCities (selected) {
        if (cities.length > 0) {
            setDropdownCities([]);

            const citiesFiltered = cities.filter(element => element === selected);
            props.setCity(citiesFiltered);

        }
    }

    const disableButton = !(isZipcodeValid && isPhoneValid && isFormValid)

    function onBlurCep (e) { 
        if(!foreigner) {
            let cep = e.target.value;

            if (!cep) {
                e.target.classList.add('error');
            } else {
                e.target.classList.remove('error');

                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then(response => {
                        response.json()
                        .then(res => {
                            if (res.erro) {
                                setModalError(true);
                                setModalErrorMessage('CEP invalido!');

                                props.setCep('');
                                props.setAddress('');
                                props.setDistrict('');
                                props.setState('');
                                props.setCity('');

                                return null;
                            }

                            props.setAddress(res.logradouro);
                            props.setDistrict(res.bairro);
                            props.setState(res.uf);
                            props.setCity(res.localidade);
                        });
                    })
                    .catch(() => {
                        setModalError(true);
                        setModalErrorMessage('CEP invalido!');

                        props.setCep('');
                        props.setAddress('');
                        props.setDistrict('');
                        props.setState('');
                        props.setCity('');

                        return null;
                    });
            };
        } else {
           (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); 
        }
    }

    return (
        <>
            <Wizard step={4} navigate={true} changeStep={changeStep} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError}  onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container>
                <Line>
                    <Input 
                        required
                        value={props.cep} 
                        onChange={(v) => foreigner ? props.setCep(v) :props.setCep(maskCepInput(v.replace(NUMBER_ONLY, "")))} 
                        onBlur={(e) => onBlurCep(e)}
                        type="text" 
                        label={foreigner ? "Código postal" : "CEP"}
                        placeholder="00000-000"
                    ></Input>
                    <Empty></Empty>
                    <Empty></Empty>
                    <Empty></Empty>
                    <Empty></Empty>
                </Line>
                <Line>
                    <Input required value={props.address} onChange={(v) => props.setAddress(v)} fl={2} label="Endereço" type="text" placeholder="Rua leopoldina" />
                    <Input required value={props.number} onChange={(v) => props.setNumber(v)} fl={1} label="Número" type="text" placeholder="0000" />
                    <Input value={props.complement} onChange={(v) => props.setComplement(v)} fl={1} label="Complemento" type="text" placeholder="" />
                </Line>
                {foreigner ? (
                    <Line>
                        <Input autofocus={targetField === 'country'}
                            value={props.country}
                            label={'País'}
                            targetName={'country'}
                            onChange={(v) => props.setCountry(v)}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                            type="text" placeholder="" required
                        />
                        <Input autofocus={targetField === 'state'}
                            targetName={'state'}
                            value={props.state} 
                            onChange={(v) => props.setState(v)}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                            type="text" label="Estado" placeholder="" required
                        />
                        <Input autofocus={targetField === 'city'}
                            value={props.city}
                            label={'Cidade'}
                            targetName={'city'}
                            onChange={(v) => props.setCity(v)}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                            type="text" placeholder="" required
                        />
                    </Line>
                ) : (
                    <Line>
                        <Input required value={props.state} onChange={(v) => props.setState(v)} fl={1} label="Estado" type="select">
                            <option value="RO">RO</option>
                            <option value="AC">AC</option>
                            <option value="AM">AM</option>
                            <option value="RR">RR</option>
                            <option value="PA">PA</option>
                            <option value="AP">AP</option>
                            <option value="TO">TO</option>
                            <option value="MA">MA</option>
                            <option value="PI">PI</option>
                            <option value="CE">CE</option>
                            <option value="RN">RN</option>
                            <option value="PB">PB</option>
                            <option value="PE">PE</option>
                            <option value="AL">AL</option>
                            <option value="SE">SE</option>
                            <option value="BA">BA</option>
                            <option value="MG">MG</option>
                            <option value="ES">ES</option>
                            <option value="RJ">RJ</option>
                            <option value="SP">SP</option>
                            <option value="PR">PR</option>
                            <option value="SC">SC</option>
                            <option value="RS">RS</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="GO">GO</option>
                            <option value="DF">DF</option>
                        </Input>
                        <Autocomplete
                            style={{
                                opacity: cities.length > 0 ? 1 : .5
                            }}
                            value={props.city}
                            label={'Cidade'}
                            targetName={'city'}
                            required
                            onChange={(v) => handleCityInput(v)}
                            onSelect={e => handleCities(e)}
                            onSelectChange={(e) => props.setCity(e)}
                            onBlur={() => setDropdownCities([])}
                            data={props.city && drowdownCities}
                        />
                    </Line>
                )}
                <Line>
                    <Input required value={props.primaryPhone} onChange={(v) => props.setPrimaryPhone(maskPhoneInput(v.replace(NUMBER_ONLY, "")))} fl={1} label="Telefone principal" type="text" placeholder="Informe o número com código de área" />
                    <Input required value={props.secondaryPhone} onChange={(v) => props.setSecondaryPhone(maskPhoneInput(v.replace(NUMBER_ONLY, "")))} fl={1} label="Telefone alternativo" type="text" placeholder="Informe o número com código de área" />
                </Line>
            </Container>
            <Controllers>
                <button onClick={props.goBack}>Cancelar</button>
                <button
                    style={{
                        opacity: disableButton ? .5 : 1
                    }}
                    onClick={() => disableButton ? focusOnLastRequiredField() : save()}>Salvar</button>
            </Controllers>
        </>
    )
}