import { Input } from "../../../components/Input";
import { Wizard } from "../../../components/Wizard";
import { Container, Line, Empty, Genero, Controllers } from "./styles";

import ValidationBuilder from '../../../data/validation/builder'
import { maskDocumentInput } from "../../../data/formatting/document-formats/document-formats";
import { NUMBER_ONLY } from "../../../data/regex";
import { ModalDefault } from "../../../components/ModalDefault";
import { useState } from "react";

const cpfValidator = ValidationBuilder("cpf")

const emailValidator = ValidationBuilder("email")

export function Password(props) {
    const { 
        cpf, 
        email, 
        email2,
        currentPassword,
        newPassword,
        password2,
        changeStep, 
        save, 
        foreigner, 
        foreignerDocument 
    } = props

    const [targetField, setTargetField] = useState('cpf')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const hasEmail = email !== '' || email2 !== '';

    const isCPFValid = !cpfValidator.validateString(cpf.replace(NUMBER_ONLY, "")).error

    const isEmailValid = email !== '' || foreigner ? !emailValidator.validateString(email).error : true;

    const isEmailMatching = hasEmail || foreigner ? email === email2 : true

    const isPasswordMatching = newPassword === password2;

    const validateForeignerDocument = foreignerDocument !== '';
    

    const disableButton = foreigner ? !(validateForeignerDocument && isEmailValid && isEmailMatching) : !(isCPFValid && isPasswordMatching && (hasEmail ? isEmailValid && isEmailMatching : true))

    const validationObject = {
        cpf: {
            field: 'cpf',
            message: 'O campo de CPF está inválido'
        },
        email: {
            field: 'email',
            message: 'O campo e-mail está inválido'
        },
        emailMismatch: {
            field: 'email2',
            message: 'Os campos de e-mail não coincidem',
        },
        foreignerDocumentIsEmpty: {
            field: 'foreignerDocument',
            message: 'O campo de documento do estrangeiro não pode estar vazio'
        },
        currentPassword: {
            field: 'currentPassword',
            message: 'Informe sua senha antiga'
        },
        password: {
            field: 'newPassword',
            message: 'O campo de senha precisa de 8 caracteres ou mais'
        },
        passwordMismatch: {
            field: 'password2',
            message: 'Os campos de senha não coincidem'
        },
    };

    const focusOnLastRequiredField = () => {
        const getErrorEntry = !isCPFValid ? 'cpf' : !isEmailValid ? 'email' : !isEmailMatching ?
        'emailMismatch' :  '';

        const getErrorEntryForeigner = !validateForeignerDocument ? 'foreignerDocumentIsEmpty' : !isEmailValid ? 'email' : !isEmailMatching ? 'emailMismatch' :  ''

        const getFirstInvalidField = foreigner ? validationObject[getErrorEntryForeigner] :validationObject[getErrorEntry]
        
        setTargetField(' ')
        
        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
        setTargetField(getFirstInvalidField.field)
    }

    const closeModalShowLastRequiredField = () => {
        const getErrorEntry = !isCPFValid ? 'cpf' : !isEmailValid ? 'email' : !isEmailMatching ?
        'emailMismatch' :  '';

        const getErrorEntryForeigner = !validateForeignerDocument ? 'foreignerDocumentIsEmpty' : !isEmailValid ? 'email' : !isEmailMatching ? 'emailMismatch' :  ''

        const getFirstInvalidField = foreigner ? validationObject[getErrorEntryForeigner] :validationObject[getErrorEntry]

        setModalError(false)
        setTargetField(' ')

        if(getFirstInvalidField.field === targetField) {
            setTargetField(' ')
            const timedOutSetField = setTimeout(() => 
            setTargetField(getFirstInvalidField.field), 256)
            return () => clearTimeout(timedOutSetField)
        }
    }

    return (
        <>
            <Wizard step={3} navigate={true} changeStep={changeStep} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container tabIndex="1">
                <Line>
                    <Input targetName={'password'} value={props.currentPassword} onChange={(v) => props.setCurrentPassword(v)} label="Senha antiga" type="password" placeholder="Senha de acesso antiga" />
                </Line>
                <Line>
                    <Input targetName={'password'} value={props.newPassword} onChange={(v) => props.setNewPassword(v)} label="Nova senha" type="password" placeholder="Nova senha de acesso" />
                    <Input targetName={'password'} value={props.password2} onChange={(v) => props.setPassword2(v)} label="Confirmar nova senha" type="password" placeholder="Repetir nova senha de acesso" />
                </Line>
            </Container>
            <Controllers>
                <button onClick={props.goBack}>Cancelar</button>
                <button
                    style={{
                        opacity: disableButton ? .5 : 1
                    }}
                    onClick={() => disableButton ? focusOnLastRequiredField() : save()}
                >Salvar</button>
            </Controllers>
        </>
    )
}