import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Body = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;
  @media screen and (max-width: 720px) {
    width: 100vw;
    margin: 0 16px;
  }

  .markdown {
    a {
      text-decoration: underline;
    }
  }
`;

export const StatusButton = styled.button`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.textColor}88;
  background-color: transparent;
  padding-bottom: 2px;
  font-size: 24px;
  margin-top: 8px;
  margin-right: 18px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 720px) {
    width: 100vw;
  }
`;

export const RowSummary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 1px solid ${(props) => props.theme.textColor}88;
  padding-bottom: 12px;
  margin-bottom: 16px;
`;

export const RowActionButtons = styled.button`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  border-color: transparent;
  background-color: transparent;

  & svg {
    fill: ${(props) => props.theme.textColor}88;
  }
`;

export const RowButtons = styled.div`
  display: flex;
`;

export const RowTitle = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize + 16}px;
`;

export const InvisibleLabel = styled.span`
  position: absolute;
  opacity: 0;
`;

export const RowSummaryText = styled.div`
  width: 50vw;
`;

export const RowDescription = styled.p`
  font-size: ${(props) => props.theme.fontSize + 14}px;
  margin-top: 10px;
`;
