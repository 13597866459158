import { useContext, useRef, useState } from "react"
import { Link } from "react-router-dom"
import AuthContext from "../../../../contexts/auth"
import ValidationBuilder from "../../../../data/validation/builder"
import api from "../../../../services/api"
import {
  ErrorMessage
} from './styles'
import { ModalDefault } from "../../../../components/ModalDefault";

const emailValidator = ValidationBuilder("email")

export const Login = ({ close, setLoginTitle }) => {
  const { LoginCustomer } = useContext(AuthContext)

  const [userName, setUserName] = useState('')

  const [userPassword, setUserPassword] = useState('')

  const [email, setEmail] = useState('')

  const [showRecoveryFlow, setShowRecoveryFlow] = useState(false)

  const [showNewPassword, setShowNewPassword] = useState(false)

  const [code, setCode] = useState('')

  const [newPassword, setNewPassword] = useState('')

  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

  const [modalError, setModalError] = useState(false);
  const [modalSuccessChangePassword, setModalSuccessChangePassword] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('')

  const emailRef = useRef()

  const codeRef = useRef()

  const isEmailValid = !emailValidator.validateString(email).error

  const userNameIsEmpty = userName.length === 0

  const userPasswordIsEmpty = userPassword.length === 0

  const disableSendButton = userNameIsEmpty || userPasswordIsEmpty

  const handleLogin = () => {
    const closeLoginModal = () => close()
    LoginCustomer(userName, userPassword, () => {
      closeLoginModal();
      window.location.reload(false);
    })
  }

  const handleGoBack = () => {
    setLoginTitle('Login')
    setShowRecoveryFlow(false)
  }

  const closeModalShowLastRequiredField = () => {
    setModalError(false)
    setModalErrorMessage("")
  }

  if (showNewPassword) {
    const handleUpdatePassword = async () => {
      try {
        await api.post('/user/update-password', {
          code,
          password: newPasswordConfirmation
        })
        setCode('')
        setNewPassword('')
        setNewPasswordConfirmation('')
        setModalSuccessChangePassword(true)
      } catch (error) {
        console.log(error)

        if (JSON.stringify(error).search('400') > -1) {
          setModalError(true)
          setModalErrorMessage("Código inválido")
          return
        }
        setModalError(true)
        setModalErrorMessage("Ocorreu um erro desconhecido, tente novamente mais tarde")
      }
    }

    const formIsEmpty = [code, newPassword].filter((item) => item.length === 0).length > 0
    // console.log("🐌🧲 ~ file: index.jsx ~ line 76 ~ Login ~ formIsEmpty", [code, newPassword].filter((item) => item.length === 0))
    let errorMessage = ''
    const passwordIsSmall = newPassword.length < 7
    // console.log("🐌🧲 ~ file: index.jsx ~ line 78 ~ Login ~ passwordIsSmall", passwordIsSmall)

    const passwordMismatch = newPassword !== newPasswordConfirmation
    // console.log("🐌🧲 ~ file: index.jsx ~ line 81 ~ Login ~ passwordMismatch", passwordMismatch)

    const shouldDisableNewPasswordButton = formIsEmpty || passwordIsSmall || passwordMismatch
    // console.log("🐌🧲 ~ file: index.jsx ~ line 84 ~ Login ~ shouldDisableNewPasswordButton", shouldDisableNewPasswordButton)

    let accessibleValidation = ''

    if (passwordIsSmall) {
      accessibleValidation = 'A nova senha precisa de 7 caracteres ou mais'
    }

    if (passwordIsSmall && newPassword.length > 0) {
      errorMessage = 'A nova senha precisa de 7 caracteres ou mais'
    }

    if (passwordMismatch && accessibleValidation.length === 0) {
      accessibleValidation = 'As senhas não coincidem'
    }
    if (errorMessage.length === 0 && passwordMismatch && newPasswordConfirmation.length > 0) {
      errorMessage = 'As senhas não coincidem'
    }

    if (errorMessage.length === 0 && code.length === 0) {
      errorMessage = 'O campo código é obrigatório'
    }

    if (code.length === 0 && accessibleValidation.length === 0) {
      accessibleValidation = 'O campo código é obrigatório'
    }

    const handleValidation = () => {
      setModalError(true)
      setModalErrorMessage(accessibleValidation)
    }

    return <>
      <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} overlay="overlay-login-error"
          onRequestClose={() => closeModalShowLastRequiredField()}>
          <p>{modalErrorMessage}</p>
      </ModalDefault>
      <ModalDefault type="error" showCloseButton title="Sucesso!" isOpen={modalSuccessChangePassword} overlay="overlay-login-error"
          onRequestClose={() => {
              setShowNewPassword(false)
              handleGoBack()
            }}>
          <p>Senha alterada com sucesso, você já pode utilizá-la para realizar o login na plataforma</p>
      </ModalDefault>
      <div style={{
        width: '100%',
        marginTop: 16
      }}>
        <p style={{
          marginBottom: 14
        }}>
          Código enviado por e-mail
        </p>
        <input type="text"
          tabIndex={0}
          autoFocus={true}
          aria-label="Código enviado por e-mail"
          onKeyDown={(e) => e.stopPropagation()}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Código" />
      </div>
      <div style={{
        width: '100%',
        marginTop: 16
      }}>
        <p style={{
          marginBottom: 14
        }}>
          Digite a nova senha
        </p>
        <input
          aria-label="Nova senha"
          type="text"
          tabIndex={0}
          onKeyDown={(e) => e.stopPropagation()}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Senha" type="password"
        />
      </div>
      <div style={{
        width: '100%',
        marginTop: 16
      }}>
        <p
          style={{
            marginBottom: 14
          }}>
          Confirme a nova senha
        </p>
        <input
          aria-label="Confirme a nova senha"
          type="text"
          tabIndex={0}
          onKeyDown={(e) => e.stopPropagation()}
          value={newPasswordConfirmation}
          onChange={(e) => setNewPasswordConfirmation(e.target.value)}
          placeholder="Confirme a nova senha"
          type="password"
        />
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      </div>

      <button style={{
        opacity: shouldDisableNewPasswordButton ? .5 : 1,
        marginTop: 22
      }}
        onClick={shouldDisableNewPasswordButton ? handleValidation : handleUpdatePassword}
        className="send"
      >
        Alterar senha
      </button>
    </>
  }

  if (showRecoveryFlow) {

    const disableRecoverButton = !isEmailValid

    const handleEmailFlow = async () => {
      try {
        await api.post(`/user/recovery-email`, {
          email
        })
        setShowNewPassword(true)
        setLoginTitle('Nova senha')
      } catch (error) {
        console.log(error)
        if (JSON.stringify(error).search('400') > -1) {
          setModalError(true)
          setModalErrorMessage("E-mail não cadastrado no sistema")
          return
        }
        setModalError(true)
        setModalErrorMessage("Erro inesperado, tente novamente mais tarde.")
      }
    }

    const closeModalShowLastRequiredField = () => {
      setModalError(false)
      setModalErrorMessage("")
    }
    return <>
      <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} overlay="overlay-login-error"
          onRequestClose={() => closeModalShowLastRequiredField()}>
          <p>{modalErrorMessage}</p>
      </ModalDefault>
      <div style={{
        width: '100%'
      }}>
        <p style={{
          marginBottom: 16,
          textAlign: 'center',
          fontSize: 14
        }}>
          Um código para recuperar a senha será enviado para sua conta
        </p>
        <p style={{
          marginBottom: 14
        }}>Digite o e-mail cadastrado</p>
        <input
          aria-label="Informe o e-mail cadastrado"
          type="text"
          tabIndex={0}
          onKeyDown={(e) => e.stopPropagation()}
          value={email}
          autoFocus={true}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail"
          type="text" />
        <ErrorMessage>
          {disableRecoverButton && email.length > 0 ? "E-mail inválido" : ""}
        </ErrorMessage>
      </div>

      <button style={{
        opacity: disableRecoverButton ? .5 : 1
      }}
        onClick={disableRecoverButton ? null : handleEmailFlow}
        className="send">
        Enviar código
      </button>
    </>
  }

  const handleOpenRecovery = () => {
    setLoginTitle('Recuperar senha')
    setShowRecoveryFlow(true)
  }



  return <>
    <input type="text"
      tabIndex={0}
      aria-label="Informe seu CPF ou CNPJ ou seu e-mail utilizado no cadastro"
      onKeyDown={(e) => e.stopPropagation()}
      value={userName} onChange={(e) => setUserName(e.target.value)}
      placeholder="Informe seu CPF ou CNPJ ou e-mail" />
    <input
      aria-label="Informe sua senha"
      type="text"
      tabIndex={0}
      onKeyDown={(e) => e.stopPropagation()}
      value={userPassword} onChange={(e) => setUserPassword(e.target.value)} placeholder="Senha" type="password" />
    <button onClick={handleOpenRecovery} aria-label="Recuperar senha" className="forgot">Esqueci a senha</button>
    <button style={{
      opacity: disableSendButton ? .5 : 1
    }}
      onClick={disableSendButton ? null : handleLogin}
      className="send">
      Entrar
    </button>
    <p>Quero me cadastrar como <Link to="/cadastro"
      aria-label="Cadastrar como pessoa física"
      className="register">Pessoa Física</Link> </p>
    <p>Quero me cadastrar como <Link
      to="/cadastro-empresa"
      className="register">Pessoa Juridica</Link> </p>
  </>
}