import { createRef, useContext, useEffect, useRef, useState } from "react"
import AuthContext from "../../contexts/auth"
import { GoBack } from '../../components/GoBack';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import {
    CardRow,
    ImageSelector,
    ImagePreview,
    Card,
    AudioCardHeader,
    CardList,
    AudioCardSummary,
    AudioCardSummaryRow,
    ImageDivFallback,
    ImageSelectorContainer,
    ImageThumbnailSelector,
    PlayerContainer,
    PlayerButtons,
    AudioTitle,
    AudioLabel,
    AudioButton,
    AudioSize,
    PrevButton,
    NextButton,
    PlayButton,
    PlayerVelocity,
    PlayerDuration,
    PlayerProgress
} from './styles'
import { useHistory, useLocation } from "react-router-dom";

export const AudioBook = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true)
    const { selectedBook, user } = useContext(AuthContext)

    const [stage, setStage] = useState(0)

    const [velocity, setVelocity] = useState(1);
    
    const [progressPercentage, setProgressPercentage] = useState(0)

    const [hover, setHover] = useState('0px')

    const [audioData, setAudioData] = useState([]);
    
    const [playerData, setPlayerData] = useState([]);

    const [getPlayerData, setGetPlayerData] = useState(false);

    const [playing, setPlaying] = useState(false);

    const [playingChapterName, setPlayingChapterName] = useState('');

    const [playingChapterProgress, setPlayingChapterProgress] = useState('0:00');

    const [playingChapterProgressPercentage, setPlayingChapterProgressPercentage] = useState(0);

    const [playingChapterSize, setPlayingChapterSize] = useState('0:00');

    const history = useHistory()

    const audio_chapters = useRef([]);

    const selectedImage = selectedBook.images ? selectedBook.images[stage] : undefined

    const [targetImageSize, setTargetImageSize] = useState({
        width: 120,
        height: 250
    })

    useEffect(() => {        
        if (selectedBook.id === undefined) {
            history.push('/buscar')
        }
    }, []);

    useEffect(() => {
        if (selectedImage) {
            if (!selectedImage.file) return
            const newImage = document.createElement('img')
            newImage.setAttribute('src', selectedImage.file?.url)

            newImage.onload = () => {
                const proportion = newImage.width / newImage.height

                setTargetImageSize({
                    width: proportion * 250,
                    height: 250
                });
            }
        }
    }, [stage]);

    useEffect(() => {
        setAudioData(audio_chapters.current.map(x => x.current));
    }, [audio_chapters]);

    useEffect(() => {
        if (!playing && playerData.filter(p => p.selected)[0] && (location.state?.play || false)) {
            setPlaying(true);
            playerData.filter(p => p.selected)[0].player.play();
            playerData.filter(p => p.selected)[0].playing = true;
        }

        setPlayingChapterName(playerData.filter(p => p.selected)[0]?.name);;
        setPlayingChapterProgress(playerData.filter(p => p.selected)[0]?.progress);
        setPlayingChapterProgressPercentage(playerData.filter(p => p.selected)[0]?.progressPercentage);
        setPlayingChapterSize(playerData.filter(p => p.selected)[0]?.size);
    }, [playerData]);

    if (selectedBook.id === undefined) {
        return null
    };

    const audiochapters = selectedBook?.chapters;

    audio_chapters.current = audiochapters.map((c, i) => audio_chapters.current[i] || createRef());

    return <div>
        <Header />
        <GoBack
            ariaLabel={"Voltar para página anterior"}
            title="Publicação"
        />
        <CardRow>
            <ImageSelector>
                <ImagePreview>
                    <ImageDivFallback
                        aria-label={selectedImage.caption}
                        bgImage={selectedImage ?
                            selectedImage.file ?
                                selectedImage.file?.url :
                                undefined
                            :
                            undefined}
                        style={{
                            width: targetImageSize.width,
                            height: targetImageSize.height
                        }}
                    />
                </ImagePreview>
                <ImageSelectorContainer>
                    {
                        selectedBook.images.map((item, key) => <ImageThumbnailSelector
                            onClick={() => key !== stage ? setStage(key) : null}
                            active={key === stage}
                            key={key + 'imgselector'}
                        />)
                    }
                </ImageSelectorContainer>
                <PlayerContainer>
                    <AudioTitle>{ playingChapterName }</AudioTitle>
                    
                    <PlayerButtons>
                        <PrevButton aria-label='Voltar'
                            onClick={(e) => {
                                setPlaying(false);
                                playerData.filter(p => p.selected)[0].player.pause();
                                let key = playerData.filter(p => p.selected)[0].key - 1;

                                if (playerData.filter(p => p.selected)[0].key != key) {
                                    playerData.filter(p => p.selected)[0].playing = false;
                                    playerData.filter(p => p.selected)[0].selected = false;
                                    playerData.filter(p => p.key === key)[0].selected = true;
                                }
                            
                                setPlaying(true);
                                audioData[key]?.play();
                                playerData.filter(p => p.selected)[0].playing = true;

                                setPlayerData(playerData);

                                setPlayingChapterName(playerData.filter(p => p.selected)[0]?.name);;
                                setPlayingChapterProgress(playerData.filter(p => p.selected)[0]?.progress);
                                setPlayingChapterProgressPercentage(playerData.filter(p => p.selected)[0]?.progressPercentage);
                                setPlayingChapterSize(playerData.filter(p => p.selected)[0]?.size);
                            }}
                            disabled={(playerData.filter(p => p.selected)[0]?.key || 0) === 0} />

                        <PlayButton aria-label='Play'
                        className={playing ? 'pause' : null}
                        onClick={() => {
                            let key = playerData.filter(p => p.selected)[0]?.key || 0;

                            if (key === 0 && !playingChapterName && !playingChapterProgress && !playingChapterSize) {
                                setPlayingChapterName(playerData.filter(p => p.selected)[0].name);;
                                setPlayingChapterProgress(playerData.filter(p => p.selected)[0].progress);
                                setPlayingChapterProgressPercentage(playerData.filter(p => p.selected)[0].progressPercentage);
                                setPlayingChapterSize(playerData.filter(p => p.selected)[0].size);  
                            }

                            if (!playing) {
                                setPlaying(true);
                                audioData[key].play();
                                playerData.filter(p => p.selected)[0].playing = true;
                            } else {
                                setPlaying(false);
                                audioData[key].pause();
                                playerData.filter(p => p.selected)[0].playing = false;
                            }
                        }} />

                        <NextButton aria-label='Próximo' 
                            onClick={(e) => {
                                setPlaying(false);
                                playerData.filter(p => p.selected)[0].player.pause();
                                let key = playerData.filter(p => p.selected)[0].key + 1;

                                if (playerData.filter(p => p.selected)[0].key != key) {
                                    playerData.filter(p => p.selected)[0].playing = false;
                                    playerData.filter(p => p.selected)[0].selected = false;
                                    playerData.filter(p => p.key === key)[0].selected = true;
                                }
                            
                                setPlaying(true);
                                audioData[key]?.play();
                                playerData.filter(p => p.selected)[0].playing = true;

                                setPlayerData(playerData);

                                setPlayingChapterName(playerData.filter(p => p.selected)[0]?.name);;
                                setPlayingChapterProgress(playerData.filter(p => p.selected)[0]?.progress);
                                setPlayingChapterProgressPercentage(playerData.filter(p => p.selected)[0]?.progressPercentage);
                                setPlayingChapterSize(playerData.filter(p => p.selected)[0]?.size);
                            }}
                            disabled={(playerData.filter(p => p.selected)[0]?.key) === playerData.length - 1} />
                    </PlayerButtons>

                    <PlayerVelocity onClick={(e) => {
                        if (velocity === 1) {
                            audioData[playerData.filter(p => p.selected)[0]?.key].playbackRate = 1.5;
                            setVelocity('1.5');
                        } else if (velocity === '1.5') {
                            audioData[playerData.filter(p => p.selected)[0]?.key].playbackRate = 2;
                            setVelocity(2);
                        } else if (velocity === 2) {
                            audioData[playerData.filter(p => p.selected)[0]?.key].playbackRate = 3;
                            setVelocity(3);
                        } else if (velocity === 3) {
                            audioData[playerData.filter(p => p.selected)[0]?.key].playbackRate = 1;
                            setVelocity(1);
                        }
                    }}>{ velocity }x</PlayerVelocity>
                    <PlayerDuration>
                        <span>{ playingChapterProgress }</span>
                        <span>{ playingChapterSize }</span>
                    </PlayerDuration>
                    <PlayerProgress onMouseMove={(e) => {
                        const left = e.target.offsetLeft;
                        const width = e.target.offsetWidth;
                        const w = width / 100;

                        const pointerPosition = e.nativeEvent.clientX;
                        const position = pointerPosition - left;
                        const p = position / w;

                        setHover(`${p}%`);
                    }}

                    onMouseLeave={() => { setHover('0%'); }}

                    onClick={(e) => {
                        const left = e.target.offsetParent.offsetLeft;
                        const width = e.target.offsetParent.offsetWidth;
                        const w = width / 100;

                        const pointerPosition = e.nativeEvent.clientX;
                        const position = pointerPosition - left;
                        const p = position / w;

                        let key = playerData.filter(p => p.selected)[0].key;

                        let split = playerData[key].size.split(':');
                        let size = (split[0] * 60) + parseInt(split[1]);
                            
                        setPlayingChapterProgressPercentage(p);
                        playerData[key].progressPercentage = p;

                        setPlayerData(playerData);
                        audioData[key].currentTime = (size / 100) * (p * w);
                    }}>
                        <span className={'progress'} style={{ width: `${playingChapterProgressPercentage}%` }}></span>
                        <span className={'hover'} style={{ width: hover }}></span>
                    </PlayerProgress>
                </PlayerContainer>
            </ImageSelector>
            <Card>
                <AudioCardHeader>
                    <h2 tabIndex={0}>
                        {selectedBook.bookTitle}
                    </h2>
                    <p tabIndex={0}>
                        {selectedBook.author}
                    </p>
                    <p tabIndex={0}>
                        {selectedBook.release}
                    </p>
                </AudioCardHeader>
                <CardList>
                    {
                        selectedBook.chapters.map(({ id, chapter_name, chapter_audio }, key) => {
                            return <AudioCardSummary key={key}>
                                <AudioCardSummaryRow className={(playerData[key]?.playing) ? 'pause' : null}>
                                    <AudioButton onClick={() => {
                                        if (playerData.filter(p => p.selected)[0]?.key != key) {
                                            playerData.filter(p => p.selected)[0].selected = false;
                                            playerData.filter(p => p.key === key)[0].selected = true;

                                            if (playerData.filter(p => p.playing)[0]) {
                                                playerData.filter(p => p.playing)[0].player.pause();
                                                playerData.filter(p => p.playing)[0].playing = false;
                                            }
                                        }
                                        
                                        if (playerData[key].playing) {
                                            setPlaying(false);
                                            audioData[key].pause();
                                            playerData.filter(p => p.selected)[0].playing = false;
                                        } else {
                                            setPlaying(true);
                                            audioData[key].play();
                                            playerData.filter(p => p.selected)[0].playing = true;
                                        }

                                        setPlayerData(playerData);

                                        setPlayingChapterName(playerData.filter(p => p.selected)[0]?.name);;
                                        setPlayingChapterProgress(playerData.filter(p => p.selected)[0]?.progress);
                                        setPlayingChapterProgressPercentage(playerData.filter(p => p.selected)[0]?.progressPercentage);
                                        setPlayingChapterSize(playerData.filter(p => p.selected)[0]?.size);
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             viewBox="-1 -1 34 34">
                                          <circle cx="16" cy="16" r="15.9155" className="progress-bar__progress" style={{ strokeDashoffset: 100 - (playerData[key]?.progressPercentage || 0) }} />
                                        </svg>
                                    </AudioButton>
                                    <AudioLabel tabIndex={0}>
                                        {chapter_name}
                                    </AudioLabel>
                                    <AudioSize>
                                        <audio src={chapter_audio?.url}
                                        ref={audio_chapters.current[key]}
                                        onTimeUpdate={(e) => {
                                            let p = e.target.currentTime;
                                            let size = e.target.duration / 100;
                                            let progress = (p / 60).toFixed(0) + ':' + (p % 60).toFixed(0).padStart(2, 0); 

                                            setPlayingChapterProgress(progress);
                                            setPlayingChapterProgressPercentage(p / size);
                                            playerData[key].progressPercentage = p / size;
                                            setPlayerData(playerData);
                                        }}

                                        onDurationChange={(e) => {
                                            setAudioData(audio_chapters.current.map(x => x.current));

                                            setPlayerData(audioData.map((a, i) => {
                                                return {
                                                    key: i,
                                                    name: selectedBook.chapters[i].chapter_name,
                                                    player: a,
                                                    selected: (i === 0) ? true : false,
                                                    playing: false,
                                                    progress: '0:00',
                                                    progressPercentage: 0,
                                                    size: (a.duration / 60).toFixed(0).padStart(2, 0) + ':' + (a.duration % 60).toFixed(0).padStart(2, 0)
                                                };
                                            }));
                                        }}

                                        onEnded={() => {
                                            setPlaying(false);
                                            playerData.filter(p => p.selected)[0].playing = false;

                                            key++;

                                            if (audio_chapters.current[key]) {
                                                if (playerData.filter(p => p.selected)[0].key != key) {
                                                    playerData.filter(p => p.selected)[0].selected = false;
                                                    playerData.filter(p => p.key === key)[0].selected = true;
                                                }
                                            
                                                setPlaying(true);
                                                audioData[key]?.play();
                                                playerData.filter(p => p.selected)[0].playing = true;

                                                setPlayerData(playerData);

                                                setPlayingChapterName(playerData.filter(p => p.selected)[0]?.name);;
                                                setPlayingChapterProgress(playerData.filter(p => p.selected)[0]?.progress);
                                                setPlayingChapterProgressPercentage(playerData.filter(p => p.selected)[0]?.progressPercentage);
                                                setPlayingChapterSize(playerData.filter(p => p.selected)[0]?.size); 
                                            }
                                        }}>
                                        </audio>
                                        { (audioData[key]?.duration / 60).toFixed(0).padStart(2, 0) }:{ (audioData[key]?.duration % 60).toFixed(0).padStart(2, 0) }
                                    </AudioSize>
                                </AudioCardSummaryRow>
                            </AudioCardSummary>
                        })
                    }
                </CardList>
            </Card>
        </CardRow>
        <Footer />
    </div>
}