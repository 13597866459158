import { Link, useHistory } from "react-router-dom";
import { Container } from "./styles";

import logoImg from '../../assets/logo-footer.svg';
import { STORAGE_KEYS } from "../../contexts/auth";
import { ScrollButtonTop } from "../ScrollButtonTop"

import { useContext, useRef, useEffect } from "react";
import AuthContext from "../../contexts/auth";

export function Footer() {
    const context = useContext(AuthContext);

    const { focusBotton, setFocusBotton } = context;

    const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"
    const history = useHistory()

    function handleRedirectH4 (url) {
        history.push(url)
    }

    const focusButton = useRef()

    useEffect(() => {
        if (focusBotton) {
            focusButton.current?.focus();
            setFocusBotton(false);
        }
    }, [focusBotton])

    return (
        <>
        <ScrollButtonTop />
        <Container>
            <nav>
                <h3 tabIndex={2} className="ds-none">Menu inferior</h3>
                <div>
                    <h4 ref={focusButton} tabIndex={2} onClick={() => handleRedirectH4('/')}>Início</h4>
                </div>
                <div>
                    <h4 tabIndex={2} onClick={() => handleRedirectH4('/acervo')}>Acervo</h4>
                    <Link tabIndex={2} to="/acervo?stage=1">Livros</Link>
                    <Link tabIndex={2} to="/acervo?stage=2">Revistas</Link>
                    <Link tabIndex={2} to="/acervo?stage=3">Jornais</Link>
                    <Link tabIndex={2} to="/acervo?stage=4">MP3</Link>
                </div>
                <div>
                    <h4 tabIndex={2} onClick={() => handleRedirectH4('/noticias')}>Notícias</h4>
                </div>
                {
                    isLogged ?
                        <div>
                            <h4 tabIndex={2} onClick={() => handleRedirectH4('/minha-dorinateca')}>Minha Dorinateca</h4>
                            <Link tabIndex={2} to="/minha-dorinateca?stage=downloads">Downloads</Link>
                            <Link tabIndex={2} to="/minha-dorinateca?stage=loan">Empréstimos</Link>
                        </div>
                        : null
                }

                <div>
                    <h4 tabIndex={2} onClick={() => handleRedirectH4('/projetos-e-patrocinios')}>Projetos</h4>

                </div>
                <div>
                    <h4 tabIndex={2}>Ajuda</h4>
                    <Link tabIndex={2} to="/perguntas-frequentes">Perguntas Frequentes</Link>
                    <Link tabIndex={2} to="/recursos-de-acessibilidade">Recursos de Acessibilidade</Link>
                    <Link tabIndex={2} to="/bibliotecas-parceiras">Encontre uma biblioteca</Link>
                    <Link tabIndex={2} to="/politica-privacidade">Política de Privacidade</Link>
                    <Link tabIndex={2} to="/termos-de-uso">Termos de Uso</Link>
                </div>
                <div>
                    <h4 tabIndex={2} onClick={() => handleRedirectH4('/contato')}>Contato</h4>
                </div>
            </nav>
            <div>
                <img src={logoImg} alt="" />
                <p>Rua Dr. Diogo de Faria, 558 <br /> Villa Clementino, São Paulo - SP - 04037-001 </p>
            </div>
        </Container>
        </>
    )
}