import { Container } from "./styles";

import filterIco from '../../assets/icons/filter.svg';
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import AuthContext from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { ScrollButtonDown } from "../ScrollButtonDown"

export function Banner() {
    const { user, setInjectSearch } = useContext(AuthContext)

    const [search, setSearch] = useState('')
    const history = useHistory()
    const handleRedirect = () => {
        setInjectSearch(search)
        history.push("/buscar")
    }
    return (
        <Container>
            <ScrollButtonDown home={true}/>
            <div>
                <span>Olá {user.socialName ?? user.name ?? ""}</span>
                <h1>Bem vind{user.genre === 'female' ? 'a' : 'o'} à Dorinateca</h1>
                <p>Veja as novidades do acervo que preparamos pra você! <br />
                    Consulte também as notícias e outras informações sobre nossos<br />
                    projetos e parcerias. Aproveite, e tenha uma ótima leitura!
                </p>
                <div className="search">
                    <input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        type="text" placeholder="Pesquise no acervo da Dorinateca" />
                    <button onClick={handleRedirect}>Buscar</button>
                </div>
                <Link to="/buscar"> <img src={filterIco} alt="" /> Filtros de pesquisa</Link>
            </div>
        </Container>
    )
}