import styled from 'styled-components';

export const Button = styled.button`
  margin: 10px;
  width: 50px;
  height: 50px;
  background-color: #E9B00D;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export const ContainerHome = styled.div`
  position: absolute;
  width: auto;
  right: 0;
  top: 5px;
`

export const BottomButton = styled(Button)`
  margin-left: auto;
  margin-top: 1em;
`;