import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Footer } from '../../components/Footer';
import { GoBack } from '../../components/GoBack';
import { Header } from '../../components/Header';
import { Input } from '../../components/Input';
import { ModalDefault } from "../../components/ModalDefault";
import AuthContext from "../../contexts/auth";
import api from "../../services/api";
import {
    AudioTitle,
    Card,
    CardFooter,
    CardHeader,
    CardList,
    CardRow,
    CardSummary,
    CardSummaryRow,
    Description,
    DownloadOptionsContainer,
    FooterDownloadWrapper,
    ImageDivFallback,
    ImagePreview,
    ImageSelector,
    ImageSelectorContainer,
    ImageThumbnailSelector,
    Label,
    NextButton,
    PlayButton,
    PlayerButtons,
    PlayerContainer,
    PlayerDuration,
    PlayerProgress,
    PlayerVelocity,
    PrevButton
} from './styles';

export const AudioBookInfo = () => {
    const { selectedBook, isLogged, user } = useContext(AuthContext)

    const [data, setData] = useState({});

    const [stage, setStage] = useState(0)
    
    const [downloads, setDownloads] = useState([])

    const [selectedFormat, setSelectedFormat] = useState('')

    const [velocity, setVelocity] = useState(1);
    
    const [playing, setPlaying] = useState(false);

    const [progress, setProgress] = useState('0px')

    const [hover, setHover] = useState('0px')

    const [reload, setReload] = useState(false);

    const [playingChapterProgress, setPlayingChapterProgress] = useState('0:00');

    const [playingChapterProgressPercentage, setPlayingChapterProgressPercentage] = useState(0);

    const [playingChapterSize, setPlayingChapterSize] = useState('0:00');

    const history = useHistory()

    const audio = useRef();

    const [modalError, setModalError] = useState(false);

    const [modalErrorMessage, setModalErrorMessage] = useState('O usuário excedeu o limite mensal de downloads.')

    const selectedImage = selectedBook.images ? selectedBook.images[stage] : undefined

    const [targetImageSize, setTargetImageSize] = useState({
        width: 120,
        height: 250
    })

    const fetchUserCustomDownloads = async () => {
        try {
            const jwt = localStorage.getItem('@App:token');
            api.defaults.headers.Authorization = `Bearer ${jwt}`;

            const response = await api.get('/users/me');
            
            if(response.status === 200){
                if (!data) setData(response.data);
            }
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchUserCustomDownloads();
    }, [data]);

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    let downloadsCount = (new Date(user.birthDate).getMonth() === currentMonth) ? 5 : 3;
    let userDownloads = data.download_limits?.filter((d) => d.Mes === parseInt(currentMonth) + 1 && d.Ano === parseInt(currentYear));

    if (userDownloads && userDownloads[0]) downloadsCount = userDownloads[0].Downloads;

    const fetchForUserHistory = async () => {
        try {
            const response = await api.get(`/user-histories?user.id=${user.id}&_limit=-1`)
            if (response) {
                if (response.status === 200) {
                    const downloadHistory = response.data.filter(({ type }) => type === 'download')
                    setDownloads(downloadHistory)
                    return
                }
                throw new Error('Unauthorized')
            }
            throw new Error('Unexpected response')
        } catch (error) {
            console.log(error, 'error while fetching for user history')
        }
    }

    useEffect(() => {
        if (selectedBook.id === undefined) {
            history.push('/buscar')
        }
    }, [selectedBook]);

    useEffect(() => {
        if (user.id !== undefined) fetchForUserHistory()
    }, [user, reload])

    useEffect(() => {
        if (selectedImage) {
            if(!selectedImage.file) return
            const newImage = document.createElement('img')
            newImage.setAttribute('src', selectedImage.file?.url)

            newImage.onload = () => {

                const proportion = newImage.width / newImage.height

                setTargetImageSize({
                    width: proportion * 250,
                    height: 250
                })
            }
        }
    }, [stage]);

    if (selectedBook.id === undefined) {
        return null
    }

    const noFormatSelected = selectedFormat.length === 0

    const handlePostDownloadCountWithUserHistory = async () => {
        try {
            const userHistoryForm = {
                type: 'download',
                book: {
                    id: selectedBook.id
                },
                user: {
                    id: user.id
                },
                author: selectedBook.author,
                title: selectedBook.bookTitle,
                formatType: selectedFormat.toLowerCase() === 'audiobook' ? 'audiobookDigital' : 'brailleDigital'
            }
            await api.post(`/user-histories`, userHistoryForm)

            await api.post(`/books/download-increment`, {
                bookId: selectedBook.id
            })

            setReload(true);
        } catch (error) {
            console.log(error, 'error while trying to save user history with download post')
        }
    }

    const formatDictionary = {
        'Audiobook': 'Audiolivro'
    }

    const handleDownload = async () => {
        if (downloadsFilter) {
            setModalError(true);
            return
        }

        if (noFormatSelected) return
        const targetFile = selectedBook[selectedFormat.toLowerCase()]

        if (!targetFile) return

        await handlePostDownloadCountWithUserHistory();
        window.open(targetFile.url)
    }
    // console.log("🐌🧲 ~ file: index.jsx ~ line 20 ~ BookInfo ~ selectedBook", selectedBook)
    const summary = [
        {
            label: 'Editora',
            value: selectedBook.publisher
        },
        {
            label: 'Categorias',
            value: (selectedBook.categories ?? []).map(({ name }) => name).join(", ")
        },
        {
            label: 'Palavras-chave',
            value: (selectedBook.keywords ?? (selectedBook.keyword ? [selectedBook.keyword] : [])).map(({ name }) => name).join(", ")
        },
        {
            label: 'Nº de páginas',
            value: selectedBook.pages
        },
        {
            label: 'Data de publicação',
            value: selectedBook.publishedDate
        },
        {
            label: 'Local',
            value: selectedBook.local
        },
        {
            label: 'Licença',
            value: selectedBook.license.name
        },
    ]

    const availableFormats = ['Braille', 'Audiobook', 'Daisy', 'ePub'].filter((item) => !!selectedBook[item.toLowerCase()])
        .map((item) => formatDictionary[formatDictionary] ?? item)

    const getDownloadsFilter = () => {
        let filter = downloads.filter((d) => {
            let result = new Date(d.created_at).getMonth() === new Date().getMonth();
            
            return result;
        }).length >= downloadsCount;

        if (user.id === undefined) return false;

        return filter;
    }
    const downloadsFilter = getDownloadsFilter();

    return <>
        <Header />
        <GoBack
            ariaLabel={"Voltar para página anterior"}
            title="Publicação"
        />
        <ModalDefault type="error" title="Download não realizado!" isOpen={modalError} 
            onRequestClose={() => setModalError(false)}>
                <p>{modalErrorMessage}</p>
        </ModalDefault>
        <CardRow>
            <ImageSelector>
                <ImagePreview>
                    <ImageDivFallback
                        aria-label={selectedImage.caption}
                        bgImage={selectedImage ?
                            selectedImage.file ?
                                selectedImage.file?.url :
                                undefined
                            :
                            undefined}
                        style={{
                            width: targetImageSize.width,
                            height: targetImageSize.height
                        }}
                    />
                </ImagePreview>
                <ImageSelectorContainer>
                    {
                        selectedBook.images.map((item, key) => <ImageThumbnailSelector
                            onClick={() => key !== stage ? setStage(key) : null}
                            active={key === stage}
                            key={key + 'imgselector'}
                        />)
                    }
                </ImageSelectorContainer>
                { isLogged && selectedBook.audiobook?.url &&
                    <PlayerContainer>
                        <AudioTitle>{ selectedBook.bookTitle }</AudioTitle>
                        
                        <PlayerButtons>
                            <PrevButton disabled />
                            <PlayButton aria-label='Play'
                            className={playing ? 'pause' : null}
                            onClick={() => {
                                history.push('/minha-dorinateca/audio', {
                                    play: true
                                });
                            }} />
                            <NextButton disabled />
                        </PlayerButtons>

                        <PlayerVelocity onClick={(e) => {
                            if (velocity === 1) {
                                audio.current.playbackRate = 1.5;
                                setVelocity('1.5');
                            } else if (velocity === '1.5') {
                                audio.current.playbackRate = 2;
                                setVelocity(2);
                            } else if (velocity === 2) {
                                audio.current.playbackRate = 3;
                                setVelocity(3);
                            } else if (velocity === 3) {
                                audio.current.playbackRate = 1;
                                setVelocity(1);
                            }
                        }}>{ velocity }x</PlayerVelocity>
                        <PlayerDuration>
                            <span>{ playingChapterProgress }</span>
                            <span>{ playingChapterSize }</span>
                        </PlayerDuration>
                        <PlayerProgress onMouseMove={(e) => {
                            const left = e.target.offsetParent.offsetLeft;
                            const width = e.target.offsetWidth;
                            const w = width / 100;

                            const pointerPosition = e.nativeEvent.clientX;
                            const position = pointerPosition - left;
                            const p = position / w;

                            setHover(`${p * w}%`);
                        }}

                        onMouseLeave={() => { setHover('0%'); }}

                        onClick={(e) => {
                            const left = e.target.offsetParent.offsetLeft;
                            const width = e.target.offsetWidth;
                            const w = width / 100;

                            const pointerPosition = e.nativeEvent.clientX;
                            const position = pointerPosition - left;
                            const p = position / w;

                            let split = (audio.current.duration / 60).toFixed(0) + ':' + (audio.current.duration % 60).toFixed(0);
                            split = split.split(':');
                            let size = (split[0] * 60) + split[1];
                            
                            setPlayingChapterProgressPercentage(p);

                            audio.current.currentTime = (size / 100) * (p * w);
                        }}>
                            <span className={'progress'} style={{ width: `${playingChapterProgressPercentage}%` }}></span>
                            <span className={'hover'} style={{ width: hover }}></span>
                        </PlayerProgress>
                    </PlayerContainer>
                }
            </ImageSelector>
            <Card>
                <CardHeader>
                    <h2 tabIndex={0}>
                        {selectedBook.bookTitle}
                    </h2>
                    <p tabIndex={0}>
                        {selectedBook.author}
                    </p>
                    <p tabIndex={0}>
                        {selectedBook.release}
                    </p>
                    <CardSummary>
                        <h4 tabIndex={0}>Sumário</h4>
                        <p tabIndex={0}>
                            {selectedBook.description}
                        </p>
                    </CardSummary>
                </CardHeader>
                <CardList>
                    {
                        summary.map(({ label, value }, key) => {
                            return <CardSummaryRow
                                key={key + label}>
                                <Label tabIndex={0}>
                                    {label}
                                </Label>
                                <Description tabIndex={0}>
                                    {value}
                                </Description>
                            </CardSummaryRow>
                        })
                    }
                </CardList>
                <CardFooter>
                    <p>Formatos disponíveis</p>
                    <FooterDownloadWrapper>
                        {/* <AvailableFormats>
                            {
                                availableFormats.map((item, key) => {
                                    return <p tabIndex={0} key={key + 'formats'}>
                                        {item}
                                    </p>
                                })
                            }
                        </AvailableFormats> */}
                        <DownloadOptionsContainer>
                            <div>
                                <Input ariaLabel="Selecione o formato de arquivo para baixar"
                                    type="select" onChange={setSelectedFormat}>
                                    {
                                        availableFormats.map((item, key) => <option
                                            key={key + 'format'}
                                            name={item}
                                            tabIndex={0}
                                            value={item}
                                            aria-hidden={false} >
                                            {item}
                                        </option>)
                                    }
                                </Input>
                            </div>
                            <button
                                onClick={handleDownload}
                                className="send"
                                style={{
                                    opacity: noFormatSelected || downloadsFilter ? .5 : 1
                                }}
                                disabled={ noFormatSelected || downloadsFilter }
                            >
                                Baixar
                            </button>
                        </DownloadOptionsContainer>
                    </FooterDownloadWrapper>
                </CardFooter>
            </Card>
        </CardRow>
        <Footer />
    </>
}