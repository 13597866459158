import { Container } from "./styles";
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import { ScrollButtonDown } from "../ScrollButtonDown"

export function GoBack(props) {
    const history = useHistory();
    return (
        <>
            <ScrollButtonDown />
            <Container tabIndex={1}>
                <button tabIndex={0} aria-label={'Voltar para página anterior.'} onClick={props.onClick !== undefined ? props.onClick : () => history.goBack()}>
                    <FiArrowLeft size={28} />
                </button>
                <h2 tabIndex={1} aria-label={props.title}>{props.title}</h2>
            </Container>
        </>
    )
}