import styled from "styled-components"
import playsvg from '../../assets/icons/play.svg';
import pausesvg from '../../assets/icons/pause.svg';
import prevbutton from '../../assets/icons/rewind.png';
import nextbutton from '../../assets/icons/fastforward.png';


export const CardRow = styled.main`
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
  padding-top: 32px;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100vw;
    margin: 0 32px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    flex-direction: column;
    width: 100vw;
    margin: 0 32px;
  }
`;

export const ImagePreview = styled.div`
    display: flex;
    justify-content: center;
`

export const ImageDivFallback = styled.div`
  width: 200px;
  height: 350px;
  background-color: ${(props) => props.theme.textColor}aa;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  ${(props) =>
    props.bgImage ? `background-image: url(${props.bgImage});` : ''}
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    margin: 0 auto;
  }
`;

export const ImageDivContainer = styled.div``;

export const ImageSelector = styled.div`
  margin-right: 32px;
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    align-items: center;
    & button {
      width: 25%;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    margin-bottom: 16px;
  }
`;

export const ImageSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
`;

export const ImageThumbnailSelector = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.textColor}dd;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  margin: 4px;
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  padding: 20px;
  border-radius: 12px;
  box-shadow: 1px 1px 4px ${(props) => props.theme.textColor}26;
  width: 50vw;
  @media (max-width: 720px) {
    width: 85%;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 90%;
  }
`;

export const CardHeader = styled.article`
  & h2 {
    margin-bottom: 6px;
    font-size: ${(props) => props.theme.fontSize + 22}px;
  }
`;

export const AudioCardHeader = styled.article`
    & h2 {
        font-size: ${props => props.theme.fontSize + 22}px;
    }
`

export const CardList = styled.div`
  margin-top: 18px;
`;

export const CardSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;
  padding-top: 4px;
  border-bottom: 1px solid ${(props) => props.theme.textColor}44;
`;

export const AudioCardSummary = styled.div`
    padding: 0 40px;
`

export const AudioCardSummaryRow = styled.div`
    border-bottom: 1px solid ${props => props.theme.textColor}44;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;
    position: relative;
    
    &:hover {
        button {
            opacity: 1;
        }

        &:before {
            content: '';
        }
    }

    &:before {
        background: url(${playsvg}) no-repeat;
        background-color: #fff;
        background-size: contain;
        border-radius: 13px;
        height: 26px;
        left: -10px;
        position: absolute;
        top: 50%;
        transform: translateX(-100%) translateY(-50%);
        width: 26px;
    }

    &.pause:before {
        background: url(${pausesvg}) no-repeat;
        background-size: contain;
    }
`

export const AudioButton = styled.button`
    background-color: transparent;
    border: 0;
    height: 34px;
    left: -6px;
    opacity: 0;
    position: absolute;
    top: 2px;
    transform: translateX(-100%);
    width: 34px;

    svg {
      height: 34px;
      transform: rotate(90deg) scaleX(-1);
      width: 34px;
    }

    .progress-bar__progress {
      fill: none;
      stroke: #000000aa;
      stroke-dasharray: 100 100;
      stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: 1.8;
      transition: stroke-dashoffset 1s ease-in-out;
    }
`

export const Label = styled.p`
  font-weight: bold;
  margin-right: 20px;
  min-width: 40%;
  max-width: 40%;
  opacity: 0.7;
`;

export const AudioLabel = styled.p`
    font-weight: 500;
    margin-right: 20px;
    max-width: 80%;
`

export const AudioSize = styled.p`
    align-self: flex-end;
`

export const Description = styled.p``

export const CardFooter = styled.div`
  & > p {
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: bold;
  }
`;

export const CardSummary = styled.div`
  margin-top: 8px;
  & p {
    margin-top: 4px;
  }
`;


export const FooterDownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    text-align: left;
    align-self: flex-start;
    margin-bottom: 8px;
  }
  margin: 16px 0 ;
  @media (max-width: 720px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const DownloadOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  .loading {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  & button {
    border-radius: 10px;
    background: var(--pink);
    height: 50px;
    width: 100%;
    border: none;
    color: var(--white);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding-left: 42px;
    padding-right: 42px;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    justify-content: space-between;
    & button {
      width: 25%;
    }
  }
`;


export const PlayerContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`

export const AudioTitle = styled.h2`
    font-size: 14px;
    text-align: center;
`

export const PlayerButtons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5px;
    width: 100%;
`

export const PrevButton = styled.button`
    background: url(${prevbutton}) no-repeat;
    background-position: center center;
    background-size: contain;
    border: 0;
    height: 20px;
    position: relative;
    width: 20px;

    &:before {
        content: '';
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`

export const NextButton = styled.button`
    background: url(${nextbutton}) no-repeat;
    background-position: center center;
    background-size: contain;
    border: 0;
    height: 20px;
    position: relative;
    width: 20px;

    &:before {
        content: '';
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`

export const PlayButton = styled.button`
    background: url(${playsvg}) no-repeat;
    background-color: #fff;
    background-size: contain;
    border: 0;
    border-radius: 20px;
    position: relative;
    height: 40px;
    width: 40px;

    &.pause {
        background: url(${pausesvg}) no-repeat;
    }
`

export const PlayerVelocity = styled.button`
    align-self: flex-end;
    background-color: #00000044;
    border: 0;
    border-radius: 6px;
    line-height: 1;
    padding: 4px 7px;
`

export const PlayerDuration = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    margin-top: 4px;
    width: 100%;

    span {
        font-size: 12px;
    }
`

export const PlayerProgress = styled.div`
    background-color: #00000044;
    border-radius: 3px;
    cursor: pointer;
    height: 6px;
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;

    span.progress {
        background-color: #000000aa;
        border-radius: 3px;
        content: '';
        height: 6px;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    span.hover {
        background-color: #00000044;
        border-radius: 3px;
        content: '';
        height: 6px;
        position: absolute;
        top: 0;
        z-index: 2;
    }
`
export const Button = styled.button`
 border-radius: 10px;
 background: var(--pink);
 height: 50px;
 width: 100%;
 border: none;
 color: var(--white);
 font-size: 16px;
 text-transform: uppercase;
 font-weight: 700;
 padding-left: 42px;
 padding-right: 42px;
 margin: 16px 0;
 transition: 500ms linear;
 box-shadow: 1px 1px 2px ${(props) => props.theme.textColor}26;

 &:hover{ 
  transform: scale(1.01);
 }
`
