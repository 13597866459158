import {
    Container,
    Wrapper
} from "./styles";
import { useEffect, useRef } from "react";

export function Wizard(props) {
    const { navigate, step, changeStep, isEnterprise } = props
    const usa = useRef()

    useEffect(() => {
        usa.current?.focus();
    }, [])

    const handleChangeStep = (num) => {
        if (changeStep && navigate) {
            changeStep(num)
        }
    }

    return (<Wrapper>
        <Container>
            <div onClick={() => handleChangeStep(1)} ref={step === 1 ? usa : null} className={step === 1 ? "step active" : "step"} tabIndex={(step === 1) ? 1 : 2}>
                <p>1</p>
                <p>{isEnterprise ? 'Dados Institucionais' : 'Dados pessoais'}</p>
            </div>
            <span></span>
            <div onClick={() => handleChangeStep(2)} ref={step === 2 ? usa : null} className={step === 2 ? "step active" : "step"} tabIndex={(step === 2) ? 1 : 2}>
                <p>2</p>
                {
                    isEnterprise ?
                        <p tabIndex={(step === 2) ? 1 : 2}>{navigate ? 'Senha de acesso' : 'Dados de acesso'}</p>
                        : <p>{navigate ? 'E-mail' : 'Senha de acesso'}</p>
                }
            </div>
            <div onClick={() => handleChangeStep(3)} ref={step === 3 ? usa : null} className={step === 3 ? "step active" : "step"} tabIndex={(step === 3) ? 1 : 2}>
                <p>3</p>
                {
                    isEnterprise ?
                        <p tabIndex={(step === 3) ? 1 : 2}>{'Sobre o acervo'}</p>
                        :
                        <p>{navigate ? 'Senha de acesso' : 'Endereço e telefones'}</p>
                }
            </div>
            <div onClick={() => handleChangeStep(4)} ref={step === 4 ? usa : null} className={step === 4 ? "step active" : "step"} tabIndex={(step === 4) ? 1 : 2}>
                <p>4</p>
                {
                    isEnterprise ?
                        <p tabIndex={(step === 3) ? 1 : 2}>{navigate ? 'Dados adicionais' : 'Sobre o acervo'}</p>
                        :
                        <p>{navigate ? 'Endereço e telefones' : 'Dados adicionais'}</p>
                }
            </div>
            {
                navigate && isEnterprise ? null :
                    <div onClick={() => handleChangeStep(5)} ref={step === 5 ? usa : null} className={step === 5 ? "step active" : "step"} tabIndex={(step === 5) ? 1 : 2}>
                        <p>5</p>
                        <p>{navigate ? 'Dados adicionais' : 'Condicão Visual'}</p>
                    </div>
            }

        </Container>
    </Wrapper>
    )
}