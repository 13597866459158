import React, { useContext } from 'react';
import { useState } from "react"
import { TopButton } from "./styles";
import { FaArrowUp } from 'react-icons/fa';
import AuthContext from '../../contexts/auth';

export const ScrollButtonTop = () => {

  const [feedback, setFeedback] = useState("");
  const context = useContext(AuthContext);

  const { setFocusTop } = context;

  const scrollToTop = () => {
    setFeedback("");

    if (window.innerWidth <= 1220) {
      document.body.scrollTo({
        top: 100,
        behavior: 'smooth'
      });

    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    setTimeout(() => {
      setFeedback("topo da página");
    }, 100);

    setFocusTop(true);
  };

  return (
    <div>
      <TopButton tabIndex={1} aria-label='ir para topo da página' onClick={scrollToTop}>
        <div
          aria-live="assertive"
          style={{
            position: "absolute",
            left: "-9999px",
          }}
        >
          {feedback}
        </div>
        <FaArrowUp />
      </TopButton>
    </div>
  );
};
