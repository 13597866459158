import styled from 'styled-components';

export const ArticleSectionContainer = styled.section`
  margin-top: 18px;
  width: 800px;
  * {
    word-break: keep-all;
  }
  @media screen and (max-width: 720px) {
    width: 90vw;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
  }
`;
export const ArticleTitle = styled.h4`
  align-self: flex-start;
  margin-bottom: 14px;
  font-size: ${(props) => props.theme.fontSize + 18}px;
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: fit-content;
    margin: 8px 32px;
  }
`;

export const InvisibleLabel = styled.span`
  position: absolute;
  opacity: 0;
`;

export const SectionBody = styled.p`
    word-break: keep-all;
    text-align: justify;
    white-space: pre-wrap;
    a{
        text-decoration: underline;
    }
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    margin: 8px 32px;
  }
    
`

export const SectionImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    margin: 8px 32px;
  }
`;

export const SectionImageDisplayContainer = styled.div`
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
  }
`;

export const ImageCaption = styled.p`
  margin-top: 4px;
  opacity: 0.8;
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 90vw;
    margin: 8px 32px;
  }
`;

export const SectionImageDisplay = styled.div`
  width: 500px;
  background-color: ${(props) => props.textColor}aa;
  background-position: center;
  background-size: cover;
  ${(props) =>
    props.bgImage ? `background-image: url(${props.bgImage});` : ''}
  img {
      width: 100%;
    }
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 16px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    height: fit-content;
    margin: 8px 32px;
    img {
      width: 90%;
    }
  }
`;

export const SectionImageThumbnail = styled.div`
  height: 100px;
  width: 150px;
  background-color: ${(props) => props.textColor}aa;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  ${(props) =>
    props.bgImage ? `background-image: url(${props.bgImage});` : ''}
  cursor: pointer;
  @media screen and (max-width: 720px) {
    width: 100%;
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    margin: 8px 32px;
  }
`;

export const SectionImageThumbnailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 12px;
  margin-bottom: 24px;
  width: 10%;
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    margin: 8px 32px;
  }
`;
