import React, { useContext } from 'react';
import { useState } from "react"
import { BottomButton, ContainerHome } from "./styles";
import { FaArrowDown } from 'react-icons/fa';
import AuthContext from '../../contexts/auth';

export const ScrollButtonDown = ({ home }) => {

  const [feedback, setFeedback] = useState("");
  const context = useContext(AuthContext);

  const { setFocusBotton } = context;

  const scrollToBottom = () => {
    setFeedback("");

    if (window.innerWidth <= 1220) {
      document.body.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });

    } else {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }

    setTimeout(() => {
      setFeedback("fim da página");
    }, 100);

    setFocusBotton(true);
  };

  return (
    (home ?
      <ContainerHome>
        <BottomButton tabIndex={1} aria-label='ir para fim da pagina' onClick={scrollToBottom}>
          <div
            aria-live="assertive"
            style={{
              position: "absolute",
              left: "-9999px",
            }}
          >
            {feedback}
          </div>
          <FaArrowDown />
        </BottomButton>
      </ContainerHome>
      :
      <BottomButton tabIndex={1} aria-label='ir para fim da pagina' onClick={scrollToBottom}>
        <div
          aria-live="assertive"
          style={{
            position: "absolute",
            left: "-9999px",
          }}
        >
          {feedback}
        </div>
        <FaArrowDown />
      </BottomButton>)
  );
};
