import { BsCameraFill, BsPen } from 'react-icons/bs';
import { Footer } from '../../components/Footer';
import { GoBack } from '../../components/GoBack';
import { Header } from '../../components/Header';
import { ModalDefault } from '../../components/ModalDefault';
import {
  Aside,
  AvatarImage,
  ColorPreviewBox,
  Container,
  InvisibleLabel,
  Main,
} from './styles';

import { useContext, useEffect, useRef, useState } from 'react';
import { BsArrowDownUp, BsDownload } from 'react-icons/bs';

import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import placeImg from '../../assets/placeholder-avatar.jpeg';
import AuthContext, { STORAGE_KEYS } from '../../contexts/auth';
import api from '../../services/api';

export function MyDorinateca(props) {
  const { location } = props;
  const params = location.search;

  const [tab, setTab] = useState(1);
  const [data, setData] = useState({});
  const [reload, setReload] = useState(false);
  const [targetDownload, setTargetDownload] = useState({});
  const focus = useRef();

  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState(
    'O usuário excedeu o limite mensal de downloads.'
  );

  const usa = useRef()

  useEffect(() => {
    if (params) {
      if (params.search('loan') > -1) {
        setTab(2)
        return
      }
      if (params.search('downloads') > -1) {
        setTab(1)
      }
    }
  }, [params]);


  const { user, handleUserUpdate, setSelectedBook } = useContext(AuthContext)
  // console.log("🐌🧲 ~ file: index.jsx ~ line 19 ~ MyDorinateca ~ user", user)

  const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"

  const [downloads, setDownloads] = useState([])

  const [loans, setLoans] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (!isLogged) {
      history.push("/")
    }
  }, [isLogged])

  useEffect(() => {
    focus.current?.focus();
  }, []);

  const fetchForUserHistory = async () => {
    try {
      const response = await api.get(`/user-histories?user.id=${user.id}&_limit=-1`)
      if (response) {
        if (response.status === 200) {
          const downloadHistory = response.data.filter(({ type }) => type === 'download')
          const loanHistory = response.data.filter(({ type }) => type === 'loan')
          setDownloads(downloadHistory)
          setLoans(loanHistory)
          return
        }
        throw new Error('Unauthorized')
      }
      throw new Error('Unexpected response')
    } catch (error) {
      console.log(error, 'error while fetching for user history')
    }
  }

  useEffect(() => {
    if (user.id !== undefined) fetchForUserHistory();
  }, [user, reload]);

  const fetchUserCustomDownloads = async () => {
    try {
      const jwt = localStorage.getItem('@App:token');
      api.defaults.headers.Authorization = `Bearer ${jwt}`;

      const response = await api.get('/users/me');

      if (response.status === 200) {
        if (Object.keys(data).length === 0) setData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserCustomDownloads();
  }, [data]);

  const handleUpdateUserPhoto = async (event) => {
    if (event.target.files.length === 0) return;
    const file = event.target.files[0];
    const isImageRegex = new RegExp(/png|jpg|jpeg/);

    const isThisFileAValidImage = isImageRegex.test(file.type);

    if (!isThisFileAValidImage) {
      alert('Por favor escolha uma imagem válida para o avatar');
      return;
    }

    try {
      const formData = new FormData();

      formData.append('files', file);
      const response = await api.post('/upload', formData);
      if (response !== undefined) {
        if (response.status === 200) {
          const finaleForm = {
            avatar: {
              id: response.data[0].id,
            },
          };
          handleUserUpdate(finaleForm);
          return;
        }
        throw new Error('Error during user avatar update');
      }
      throw new Error('Error during upload');
    } catch (error) {
      console.log(error);
      alert('Ocorreu um erro inesperado, tente novamente mais tarde.');
    }
  };

  const fileInputRef = useRef();

  const handlePickFile = () => {
    if (fileInputRef) {
      fileInputRef.current?.click();
    }
  };

  const [sortDirection, setSortDirection] = useState(false);

  const handleSorting = (type) => {
    switch (type) {
      case 'data':
        if (tab === 1)
          setDownloads(
            downloads.sort((a, b) =>
              moment(a.created_at).valueOf() > moment(b.created_at).valueOf()
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        if (tab === 2)
          setLoans(
            loans?.sort((a, b) =>
              moment(a.expireAt).valueOf() > moment(b.expireAt).valueOf()
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        setSortDirection((prevState) => !prevState);
        return;
      case 'title':
        if (tab === 1)
          setDownloads(
            downloads.sort((a, b) =>
              a.title > b.title
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        if (tab === 2)
          setLoans(
            loans?.sort((a, b) =>
              a.title > b.title
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        setSortDirection((prevState) => !prevState);
        return;
      case 'author':
        if (tab === 1)
          setDownloads(
            downloads.sort((a, b) =>
              a.author > b.author
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        if (tab === 2)
          setLoans(
            loans?.sort((a, b) =>
              a.author > b.author
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        setSortDirection((prevState) => !prevState);
        return;
      case 'format':
        if (tab === 1)
          setDownloads(
            downloads.sort((a, b) =>
              a.formatType > b.formatType
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        if (tab === 2)
          setLoans(
            loans?.sort((a, b) =>
              a.formatType > b.formatType
                ? sortDirection
                  ? 1
                  : -1
                : sortDirection
                  ? -1
                  : 1
            )
          );
        setSortDirection((prevState) => !prevState);
        return;
      default:
        break;
    }
  };

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  let downloadsCount =
    new Date(user.birthDate).getMonth() === currentMonth ? 6 : 3;
  let userDownloads = data.download_limits?.filter(
    (d) =>
      d.Mes === parseInt(currentMonth) + 1 && d.Ano === parseInt(currentYear)
  );

  if (userDownloads && userDownloads[0])
    downloadsCount += userDownloads[0].Downloads;

  const getDownloadsFilter = (download) => {
    if (download?.book?.license?.id === 1 || download?.book?.license === 1) {
      return false;
    }
    let firstFilter = downloads.filter(
      (el) => el?.book?.license?.id === 2 || el?.book?.license === 2
    );
    let filter =
      firstFilter.filter((d) => {
        let result = new Date(d.created_at).getMonth() === new Date().getMonth() && new Date(d.created_at).getFullYear() === new Date().getFullYear();


        return result;
      }).length >= downloadsCount;

    if (user.id === undefined) return false;

    return filter;
  };

  function setFormat(selectedFormat) {
    switch (selectedFormat) {
      case "audiobook":
        return "audiobookDigital"
      case "braille":
        return "brailleDigital"
      case "html5":
        return "HTML5"
      case "epub":
        return "ePubDigital";
      case "daisy":
        return "daisyDigital"
      default:
        break;
    }
  }

  const handlePostDownloadCountWithUserHistory = async (
    selectedBook,
    formatType
  ) => {
    try {
      const userHistoryForm = {
        type: 'download',
        book: {
          id: selectedBook.id,
        },
        user: {
          id: user.id,
        },
        author: selectedBook.author,
        title: selectedBook.bookTitle,
        formatType: setFormat(formatType.toLowerCase()),

      };
      await api.post(`/user-histories`, userHistoryForm);

      await api.post(`/books/download-increment`, {
        bookId: selectedBook.id,
      });

      setReload(true);
    } catch (error) {
      console.log(
        error,
        'error while trying to save user history with download post'
      );
    }
  };
  const [modalInitDownload, setModalInitDownload] = useState(false);
  const [modalAcceptedDownload, setModalAcceptedDownload] = useState(false);

  async function downloadFile(url, fileName) {
    console.log('iniciando download file')
    console.log('url', url)
    console.log('fileName', fileName)
    // Faz a requisição para o URL fornecido
    const response = await fetch(url);
    console.log('response', response)

    // Verifica se a requisição foi bem-sucedida
    if (!response.ok) {
      console.log('error', response.statusText)
      throw new Error(`Não foi possível fazer o download do arquivo: ${response.statusText}`);
    }

    // Cria um stream de leitura a partir do corpo da resposta
    const reader = response.body.getReader();
    console.log('reader', reader)
    const contentLength = +response.headers.get('Content-Length');
    console.log('contentLength', contentLength)

    // Cria um novo array para armazenar os chunks
    let receivedLength = 0;
    const chunks = [];

    // Lê os dados da stream
    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      chunks.push(value);
      receivedLength += value.length;

      console.log(`Received ${receivedLength} of ${contentLength}`);
    }

    // Concatena os chunks em um único Uint8Array
    const chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (const chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }
    console.log('chunksAll', chunksAll)

    // Cria um Blob a partir do Uint8Array concatenado
    const blob = new Blob([chunksAll]);

    // Cria um link temporário para download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // Remove o link temporário
    document.body.removeChild(link);
  }

  const handleDownloadFromHistory = (download) => {
    const getBookFormat =
      download.formatType.search('audio') > -1 ? 'audiobook' : 'braille';
    const getBookUrl = download.book[getBookFormat];

    if (getDownloadsFilter(download)) {
      setModalError(true);
      return;
    }

    if (getBookUrl) {
      handlePostDownloadCountWithUserHistory(download.book, getBookFormat);
      window.open(getBookUrl.url);
    }
  };

  const handleSteganographyAndDownload = async () => {
    setLoading(true)
    let today = new Date();
    let messageSteganography = `O usuário ${user.name + ' ' + user.lastName
      }, do e-mail ${user.email} (id ${user.id
      }), realizou o download desse livro em ${today.getDate()}/${today.getMonth() + 1
      }/${today.getFullYear()} as ${today.getHours()}:${today.getMinutes()}`;
    let targetFile;

    if (targetDownload.book.audiobook !== null) {
      targetFile = targetDownload.book.audiobook.url;
    } else if (targetDownload.book.daisy !== null) {
      targetFile = targetDownload.book.daisy.url;
    } else if (targetDownload.book.epub !== null) {
      targetFile = targetDownload.book.epub.url;
    } else if (targetDownload.book.braille !== null) {
      targetFile = targetDownload.book.braille.url;
    }
    else if (targetDownload.book.html5 !== null) {
      targetFile = targetDownload.book.html5.url;
    }

    const postData = {
      url: targetFile,
      message: messageSteganography,
      operation: 'saveToken',
      token: new Date().getTime().toString(),
    };
    setModalInitDownload(true);
    await doRequest(postData)
      .then(async (res) => {
        await handlePostDownloadCountWithUserHistory();
        setModalInitDownload(false);
        window.open(res.body.url);
        // await downloadFile(res.body.url, res.body.url.split("/")[res.body.url.split('/').length - 1]).catch(console.error);

      })
      .catch((error) => {
        setTimeout(async () => {
          postData.operation = 'getToken';
          await doRequest(postData)
            .then(async (response) => {
              if (!response.body.url) {
                setTimeout(async () => {
                  await doRequest(postData)
                    .then(async (resp) => {
                      if (!resp.body.url) {
                        setTimeout(async () => {
                          await doRequest(postData)
                            .then(async (r) => {
                              await handlePostDownloadCountWithUserHistory();
                              setModalInitDownload(false);
                              window.open(r.body.url);
                              // await downloadFile(response.body.url, response.body.url.split("/")[response.body.url.split('/').length - 1]).catch(console.error);                              setTargetDownload({})
                              setLoading(true)
                            })
                            .catch((err) => {
                              console.error('Erro na chamada da API:', err);
                              setModalError(true);
                              setModalErrorMessage("Erro ao realizar download, tente novamente.")
                              setModalInitDownload(false);
                            });
                        }, 60000);
                      } else {
                        await handlePostDownloadCountWithUserHistory();
                        setModalInitDownload(false);
                        // await downloadFile(response.body.url, response.body.url.split("/")[response.body.url.split('/').length - 1]).catch(console.error);
                        window.open(resp.body.url);
                        setTargetDownload({})
                        setLoading(true)
                      }
                    })
                    .catch((err) => {
                      console.error('Erro na chamada da API:', err);
                      setModalError(true);
                      setModalErrorMessage("Erro ao realizar download, tente novamente.")
                      setModalInitDownload(false);
                    });
                }, 30000);
              } else {
                await handlePostDownloadCountWithUserHistory();
                setModalInitDownload(false);
                window.open(response.body.url);
                // await downloadFile(response.body.url, response.body.url.split("/")[response.body.url.split('/').length - 1]).catch(console.error);

                setTargetDownload({})
                setLoading(true)
              }
            })
            .catch((err) => {
              console.error('Erro na chamada da API:', err);
              setModalError(true);
              setModalErrorMessage("Erro ao realizar download, tente novamente.")
              setModalInitDownload(false);
            });
        }, 30000);
      });
  };

  async function doRequest(data) {
    let urlLambda = process.env.REACT_APP_PRODUCTION_ENVIRONMENT === "true"
      ? "https://3e3if6u7e2.execute-api.us-east-1.amazonaws.com/prod/encrypt-files-apidorinateca-prod"
      : "https://pi196u65z6.execute-api.us-east-1.amazonaws.com/homologacao/encrypt-files-hhh-apidorinateca"

    const response = await fetch(urlLambda, {
      method: 'POST',
      body: JSON.stringify(data),
    }
    );

    return response.json();
  }

  useEffect(() => {
    usa.current?.focus();
  }, [])

  return (
    <>
      <Header menu={3} />
      <GoBack title="Minha Dorinateca" />
      <ModalDefault
        type="error" showCloseButton
        title="Download não realizado!"
        isOpen={modalError}
        onRequestClose={() => setModalError(false)}
      >
        <p>{modalErrorMessage}</p>
      </ModalDefault>
      <ModalDefault
        type="accept"
        title="Atenção!"
        isOpen={modalAcceptedDownload}
        overlay="overlay-login-error"
        onRequestClose={() => {
          setModalAcceptedDownload(false);
        }}
        onRequestAccept={() => {
          setModalAcceptedDownload(false);
          handleSteganographyAndDownload();
        }}
      >
        <p>
          Ao realizar o download, algumas informações serão salvas (como id,
          nome e email), você aceita as condições?
        </p>
      </ModalDefault>
      <ModalDefault
        type="error" showCloseButton
        title="Iniciando download"
        isOpen={modalInitDownload}
        onRequestClose={() => {
          setModalInitDownload(false);
        }}
      >
        <p>
          O arquivo esta sendo processado e logo será disponibilizado para
          download, isso pode levar alguns minutos, não saia nem recarregue a
          página até o processo ser concluído.
        </p>
      </ModalDefault>
      <Container>
        <Main>
          <div className="tabs">
            <button
              aria-label={`Abrir histórico de downloads ${tab !== 2 ? 'aberto' : 'fechado'
                }`}
              tabIndex={1}
              ref={focus}
              onClick={() => setTab(1)}
              className={tab === 1 ? 'active' : ''}
            >
              Downloads
            </button>
            <button
              aria-label={`Abrir histórico de empréstimos ${tab === 2 ? 'aberto' : 'fechado'
                }`}
              tabIndex={1}
              onClick={() => setTab(2)}
              className={tab === 2 ? 'active' : ''}
            >
              Empréstimos
            </button>
          </div>
          {tab === 1 && (
            <table>
              <thead>
                <td>
                  <button
                    tabIndex={1}
                    aria-label={`Organizar por data`}
                    onKeyDown={({ keyCode }) =>
                      keyCode === 13 ? handleSorting('data') : null
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSorting('data')}
                  >
                    <BsArrowDownUp />
                  </button>
                  <label>Data</label>
                </td>
                <td>
                  <button
                    tabIndex={1}
                    aria-label={`Organizar por título`}
                    onKeyDown={({ keyCode }) =>
                      keyCode === 13 ? handleSorting('title') : null
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSorting('title')}
                  >
                    <BsArrowDownUp />
                  </button>
                  Título
                </td>
                <td>
                  <button
                    tabIndex={1}
                    aria-label={`Organizar por autor`}
                    onKeyDown={({ keyCode }) =>
                      keyCode === 13 ? handleSorting('author') : null
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSorting('author')}
                  >
                    <BsArrowDownUp />
                  </button>
                  Autor
                </td>
                <td>
                  <button
                    tabIndex={1}
                    aria-label={`Organizar por formato`}
                    onKeyDown={({ keyCode }) =>
                      keyCode === 13 ? handleSorting('format') : null
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSorting('format')}
                  >
                    <BsArrowDownUp />
                  </button>
                  Formato
                </td>
                <td></td>
                {/* <td></td> */}
              </thead>
              <tbody>
                {downloads.map((download, key) => {
                  const fallbackType =
                    (download.formatType ?? '').search('audio') > -1
                      ? 'Audiobook'
                      : 'Braille';
                  return (
                    <tr
                      aria-label={`Obra: ${download.book?.bookTitle}`}
                      tabIndex={0}
                      key={key + 'lista-download'}
                    >
                      <td scope='row' tabIndex={1}>
                        <InvisibleLabel>Data: </InvisibleLabel>
                        {moment(download.created_at).format('DD/MM/yyyy')}
                      </td>
                      <td scope='row' tabIndex={1}>
                        <InvisibleLabel>Obra: </InvisibleLabel>
                        {download.book?.bookTitle}
                      </td>
                      <td scope='row' tabIndex={1}>
                        <InvisibleLabel>Autor: </InvisibleLabel>
                        {download.book?.author}
                      </td>
                      <td scope='row' tabIndex={1}>
                        <InvisibleLabel>Formato: </InvisibleLabel>
                        {fallbackType}
                      </td>
                      <td scope='row' style={{ cursor: 'pointer' }}>
                        <button
                          tabIndex={1}
                          disabled={loading}
                          aria-label={
                            getDownloadsFilter(download)
                              ? 'Numero máximo de downloads atingido'
                              : `Baixar livro'${download.book?.bookTitle}' novamente.`
                          }
                          onClick={() => {
                            if (download.book.license === 2) {
                              setModalAcceptedDownload(true)
                              setTargetDownload(download)
                            } else {
                              handleDownloadFromHistory(download)
                            }
                          }}
                        >
                          <BsDownload />
                        </button>
                        Baixar
                      </td>
                      {/* <td> <BsPlayCircleFill /> Play </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {tab === 2 && (
            <table>
              <thead>
                <tr className="colors">
                  <td>
                    {' '}
                    <ColorPreviewBox bgColor="#FF0000">aa</ColorPreviewBox> Data
                    vencida
                  </td>
                  <td>
                    {' '}
                    <ColorPreviewBox bgColor="#FFE200">aa</ColorPreviewBox>{' '}
                    Próximos de vencimento
                  </td>
                  <td>
                    {' '}
                    <ColorPreviewBox bgColor="#A5FF95">aa</ColorPreviewBox>{' '}
                    Dentro do prazo
                  </td>
                  <td>
                    {' '}
                    <ColorPreviewBox bgColor="#00AAFF">aa</ColorPreviewBox>{' '}
                    Entregue
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td tabIndex={1}>
                    Data
                    <button
                      tabIndex={1}
                      aria-label={`Organizar por data`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleSorting('data')}
                    >
                      <BsArrowDownUp />
                    </button>
                  </td>
                  <td tabIndex={1}>
                    Título
                    <button
                      tabIndex={1}
                      aria-label={`Organizar por título`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleSorting('title')}
                    >
                      <BsArrowDownUp />
                    </button>
                  </td>
                  <td tabIndex={1}>
                    Autor
                    <button
                      tabIndex={1}
                      aria-label={`Organizar por autor`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleSorting('author')}
                    >
                      <BsArrowDownUp />
                    </button>
                  </td>
                  <td ref={usa} tabIndex={1}>
                    Formato
                    <button
                      tabIndex={1}
                      aria-label={`Organizar por formato`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleSorting('format')}
                    >
                      <BsArrowDownUp />
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody>
                {loans.map((loan, key) => {
                  const dateExpireMoment = moment(loan.expireAt);
                  const isExpired =
                    moment().valueOf() >= dateExpireMoment.valueOf();
                  const isDateClose = Math.abs(
                    moment().diff(dateExpireMoment, 'days')
                  );
                  const resultingColor = loan.returned
                    ? 'blue'
                    : isExpired
                      ? 'red'
                      : isDateClose <= 2
                        ? 'yellow'
                        : 'green';
                  const fallbackType =
                    (loan.formatType ?? '').search('audio') > -1
                      ? 'Audiobook'
                      : 'Braille';
                  return (
                    <tr className={resultingColor} key={key + 'loan'}>
                      <td tabIndex={1}>
                        <InvisibleLabel>Data de vencimento: </InvisibleLabel>
                        {moment(loan.expireAt).format('DD/MM/yyyy')}
                      </td>
                      <td>
                        <span
                          tabIndex={0}
                          aria-label={`Obra: ${loan?.book?.bookTitle}`}
                        >
                          <InvisibleLabel>Obra: </InvisibleLabel>
                          {loan?.book?.bookTitle}
                        </span>
                      </td>
                      <td tabIndex={1}>
                        <InvisibleLabel>Autor: </InvisibleLabel>
                        {loan.book?.author}
                      </td>
                      <td tabIndex={1}>
                        <InvisibleLabel>Formato: </InvisibleLabel>
                        {fallbackType}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Main>
        <Aside>
          <section>
            <div>
              <div>
                <AvatarImage
                  tabIndex={0}
                  src={user.avatar ? user.avatar.url : placeImg}
                  aria-label="Foto de perfil"
                  alt="foto de avatar"
                />
              </div>
              <button
                onClick={handlePickFile}
                tabIndex={0}
                aria-label="Trocar foto de perfil"
              >
                {' '}
                <BsCameraFill />
              </button>

              <input
                ref={fileInputRef}
                style={{
                  position: 'fixed',
                  right: '-100vw',
                  bottom: '-15vh',
                }}
                type="file"
                aria-hidden={true}
                onChange={handleUpdateUserPhoto}
              />
            </div>
            <p>
              <InvisibleLabel>Nome do usuário:</InvisibleLabel>
              {user.socialName ?? user.name}
            </p>
            <span>
              <InvisibleLabel>E-mail do usuário:</InvisibleLabel>
              {user.email}
            </span>
            <button onClick={() => history.push('/editar-cadastro')}>
              {' '}
              <BsPen /> Editar dados
            </button>
          </section>
        </Aside>
      </Container>
      <Footer />
    </>
  );
}
