import { Footer } from "../../components/Footer";
import { GoBack } from "../../components/GoBack";
import { Header } from "../../components/Header";
import { Container } from "./styles";
import { useState, useContext, useEffect } from 'react';
import AuthContext from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { NUMBER_ONLY } from "../../data/regex";
import { maskCepInput, maskDocumentInput, maskPhoneInput } from "../../data/formatting/document-formats/document-formats";
import { FormConstructor } from "../../components/form-cosntructor/form-constructor";

import ValidationBuilder from '../../data/validation/builder'
import axios from "axios";

const emailValidator = ValidationBuilder("email")

const cnpjValidation = ValidationBuilder("cnpj")

export function EditEnterprise() {

    const { handleUserUpdate, user } = useContext(AuthContext)
    // console.log("🐌🧲 ~ file: index.jsx ~ line 21 ~ EditEnterprise ~ user", user)
    const [step, setStep] = useState(1);

    const [name, setName] = useState(user.name ?? "");
    const [cnpj, setCnpj] = useState(user.cnpj ?? "");

    const [surname, setSurname] = useState(user.socialName ?? "");

    const [responsibleName, setResponsibleName] = useState(user.responsibleRegistration ?? "");


    const [institutionType, setInstitutionType] = useState(user.typeInstitution ?? "");

    const [email, setEmail] = useState(user.email ?? "");
    const [email2, setEmail2] = useState(user.alternativeEmail ?? "");
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [cep, setCep] = useState(user.address.zipcode ?? "");
    const [address, setAddress] = useState(user.address.address ?? "");
    const [number, setNumber] = useState(user.address.number ?? "");
    const [complement, setComplement] = useState(user.address.complement ?? "");
    const [city, setCity] = useState(user.address.city ?? "");
    const [state, setState] = useState(user.address.uf ?? "");

    const [district, setDistrict] = useState(user.address.district ?? "");
    const [primaryPhone, setPrimaryPhone] = useState(user.mainPhone ?? "");
    const [secondaryPhone, setSecondaryPhone] = useState(user.phone ?? "");

    const [occupation, setOccupation] = useState(user.occupation ?? "");


    const [attendendPublic, setAttendedPublic] = useState((user.publicByAge ?? []).map(({ name }) => name))
    // console.log("🐌🧲 ~ file: index.jsx ~ line 54 ~ EditEnterprise ~ attendendPublic", attendendPublic)

    const [profileImage, setProfileImage] = useState({})

    const [cities, setCities] = useState([])


    const fetchForSelectedStateCities = async () => {
        try {
            //here we retrieve the list of cities from IBGE API
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
            if (response.status === 200) {
                const cities = Array.from(response.data || []).map(({ nome }) => nome)
                setCities(cities)
            }

        } catch (error) {
            console.log(error, 'error')
        }
    }

    useEffect(() => {
        if (state.length === 2) {
            fetchForSelectedStateCities()
        }
    }, [state])

    const history = useHistory()

    const [drowdownCities, setDropdownCities] = useState([]);

    function handleCityInput(input) {
        setCity(input)
        if (cities.length > 0) {
            const variableToSearch = input.toLocaleLowerCase();
            const citiesFiltered = cities.filter(element => {
                return element
                    .toLocaleLowerCase()
                    .includes(variableToSearch)
            });

            setDropdownCities(citiesFiltered)
        }
    }

    function handleCities(selected) {
        if (cities.length > 0) {
            setDropdownCities([]);

            const citiesFiltered = cities.filter(element => element === selected);
            setCity(citiesFiltered);
        }
    }

    const firstStageForm = [
        {
            value: name,
            label: 'Nome da instituição',
            formLabel: 'name',
            onChange: setName,
            type: 'text',
            placeholder: 'Nome da instituição'
        },
        {
            value: cnpj,
            label: 'CNPJ',
            formLabel: 'cnpj',
            onChange: setCnpj,
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskDocumentInput(value, 'cnpj'),
            type: 'text',
            placeholder: 'CNPJ',
            validation: (value) => !cnpjValidation.validateString(value.replace(NUMBER_ONLY, "")).error
        },
        {
            value: surname,
            label: 'Razão social do CNPJ informado',
            formLabel: 'socialName',
            onChange: setSurname,
            type: 'text',
            placeholder: 'Insira a razão social',
            fl: 4
        },
        {
            value: cep,
            label: 'CEP',
            formLabel: {
                name: 'address',
                entry: 'zipcode'
            },
            onChange: setCep,
            onBlur: (e) => {
                let cep = e.target.value;

                if (!cep) {
                    e.target.classList.add('error')
                } else {
                    e.target.classList.remove('error')

                    fetch(`https://viacep.com.br/ws/${cep}/json/`)
                        .then(response => {
                            response.json()
                                .then(res => {
                                    if (res.erro) {

                                        setCep('');
                                        setAddress('');
                                        setDistrict('');
                                        setState('');
                                        setCity('');

                                        return null;
                                    }

                                    setAddress(res.logradouro);
                                    setDistrict(res.bairro);
                                    setState(res.uf);
                                    setCity(res.localidade);
                                });
                        })
                        .catch(() => {

                            setCep('');
                            setAddress('');
                            setDistrict('');
                            setState('');
                            setCity('');

                            return null;
                        })
                }
            },
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskCepInput(value),
            type: 'text',
            placeholder: 'Insira o CEP',
            fl: 1
        },
        {
            value: address,
            label: 'Endereço',
            formLabel: {
                name: 'address',
                entry: 'address'
            },
            onChange: setAddress,
            type: 'text',
            placeholder: 'Insira o endereço',
            fl: 4
        },
        {
            value: number,
            label: 'Número',
            formLabel: {
                name: 'address',
                entry: 'number'
            },
            onChange: setNumber,
            type: 'text',
            placeholder: 'Insira o número',
            fl: 1
        },
        {
            value: complement,
            label: 'Complemento',
            formLabel: {
                name: 'address',
                entry: 'complement'
            },
            onChange: setComplement,
            type: 'text',
            placeholder: 'Insira o complemento'
        },
        {
            value: district,
            label: 'Bairro',
            formLabel: {
                name: 'address',
                entry: 'district'
            },
            onChange: setDistrict,
            type: 'text',
            placeholder: 'Insira o bairro'
        },
        {
            value: state,
            label: 'Estado',
            formLabel: {
                name: 'address',
                entry: 'uf'
            },
            onChange: setState,
            type: 'select',
            placeholder: 'Insira o estado'
        },
        {
            value: city,
            label: 'Cidade',
            formLabel: {
                name: 'address',
                entry: 'city'
            },
            onChange: handleCityInput,
            onSelectChange: (e) => setCity(e),
            onBlur: (e) => setDropdownCities([]),
            type: 'autocomplete',
            drowpdownValues: drowdownCities,
            onSelect: (e) => handleCities(e),
            placeholder: 'Insira a cidade',
            options: cities,
            required: 'required'
        },
        {
            value: primaryPhone,
            label: 'Telefone principal',
            formLabel: 'mainPhone',
            onChange: setPrimaryPhone,
            type: 'text',
            placeholder: 'Informe o número com código de área',
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskPhoneInput(value),
        },
        {
            value: secondaryPhone,
            label: 'Telefone alternativo',
            formLabel: 'phone',
            onChange: setSecondaryPhone,
            type: 'text',
            optional: true,
            placeholder: 'Informe o número com código de área',
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskPhoneInput(value),
        },
        {
            value: email,
            label: 'E-mail para contato',
            formLabel: 'email',
            onChange: setEmail,
            type: 'text',
            placeholder: ' ',
            validation: (value) => !emailValidator.validateString(value).error
        },
        {
            value: email2,
            label: 'E-mail alternativo para contato',
            formLabel: 'alternativeEmail',
            onChange: setEmail2,
            type: 'text',
            placeholder: ' ',
            validation: (value) => !emailValidator.validateString(value).error
        },
        {
            value: responsibleName,
            label: 'Nome do responsável pelo cadastro',
            formLabel: 'responsibleRegistration',
            onChange: setResponsibleName,
            type: 'text',
            placeholder: 'Nome do responsável'
        },
        {
            value: occupation,
            label: 'Cargo na instituição',
            formLabel: 'occupation',
            onChange: setOccupation,
            type: 'text',
            placeholder: ' '
        },
        {
            value: institutionType,
            label: 'Tipo de instituição',
            formLabel: 'typeInstitution',
            onChange: setInstitutionType,
            type: 'select',
            placeholder: ' ',
            options: [
                {
                    label: 'Escola regular',
                    value: 'regular_school'
                },
                {
                    label: 'Escola técnica',
                    value: 'technical_school'
                },
                {
                    label: 'Universidade',
                    value: 'university'
                },
                {
                    label: 'Biblioteca pública',
                    value: 'public_library'
                },
                {
                    label: 'Organização social',
                    value: 'social_organization'
                },
                {
                    label: 'Centro especializado em pessoas com deficiência',
                    value: 'specialized_service_center_people_disabilities'
                },
                {
                    label: 'Instituto de Esporte, Lazer e Cultura',
                    value: 'institution_culture_leisure_sport'
                },
                {
                    label: 'Instituição de saúde',
                    value: 'health_institution'
                },
                {
                    label: 'Outro',
                    value: 'others'
                }
            ]
        }
    ]


    const passwordForm = [
        {
            value: currentPassword,
            label: 'Senha de acesso antiga',
            formLabel: 'currentPassword',
            onChange: setCurrentPassword,
            type: 'password',
            placeholder: ' ',
            validation: (value) => value.length > 7
        },
        {
            value: password,
            label: 'Nova senha de acesso',
            formLabel: 'newPassword',
            onChange: setPassword,
            type: 'password',
            placeholder: ' ',
            validation: (value) => value.length > 7
        },
        {
            value: password2,
            label: 'Confirmar nova senha de acesso',
            formLabel: 'password',
            onChange: setPassword2,
            type: 'password',
            placeholder: ' ',
            validation: (value) => value === password
        }
    ]

    const [receiveMailBox, setReceiveMailBox] = useState(user.additionlInformation.receiveBooksOnMailBox ?? false)

    const [receiveDownload, setReceiveDownload] = useState(user.additionlInformation.receiveBooksViaDownload ?? false)

    const [workGroup, setWorkGroup] = useState(user.groupWorkNetworkReading ?? false)

    const [targetPublic, setTargetPublic] = useState(user.publicServedDisabilities ?? "")

    const [ageGroup, setAgeGroup] = useState(user.ageGroup ?? "")

    const [kidsQuantity, setKidsQuantity] = useState(user.disabledPeopleQtyKid ?? "")

    const [teenQuantity, setTeenQuantity] = useState(user.disabledPeopleQtyAdolescent ?? "")

    const [youngAdoutQuantity, setYoungAdultQuantity] = useState(user.disabledPeopleQtyYoung ?? "")

    const [adultQuantity, setAdultQuantity] = useState(user.disabledPeopleQtyAdult ?? "")


    const [useBraille, setUseBraille] = useState(user.additionlInformation.useBraille ?? "")
    const [useAudio, setUseAudio] = useState(user.additionlInformation.useAudiobooks ?? "")
    const [useEnlarged, setUseEnlarged] = useState(user.additionlInformation.useEnlargedFonts ?? "")
    const [useBrailleInks, setUseBrailleInks] = useState(user.additionlInformation.useBrailleInks ?? "")
    const [useDaisy, setUseDaisy] = useState(user.additionlInformation.useDaisy ?? "")
    const [useEpub, setUseEpub] = useState(user.additionlInformation.useEpub3 ?? "")

    const aboutCollectionForm = [
        {
            type: 'multiple-checkbox',
            label: 'Sobre o recebimento de livros',
            items: [
                {
                    label: 'Receber pelos correios',
                    value: receiveMailBox,
                    onChange: setReceiveMailBox,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'receiveBooksOnMailBox'
                    }
                },
                {
                    label: 'Fazer download de livros',
                    value: receiveDownload,
                    onChange: setReceiveDownload,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'receiveBooksViaDownload'
                    }
                }
            ],
        },
        {
            type: 'radio',
            label: 'Faz parte de algum grupo de trabalho da Rede de Leitura Inclusiva',
            value: workGroup,
            onChange: setWorkGroup,
            formLabel: 'groupWorkNetworkReading'
        },
        {
            type: 'none'
            // label: 'Faixa etária para recebimento dos livros',
            // type: 'select',
            // value: ageGroup,
            // onChange: setAgeGroup,
            // formLabel: 'ageGroup',
            // options: [
            //     {
            //         label: 'Até 11 anos',
            //         value: 'child_0_to_11_years'
            //     },
            //     {
            //         label: 'De 12 a 17 anos',
            //         value: 'adolescent_12_to_17_years'
            //     },
            //     {
            //         label: 'De 18 a 28 anos',
            //         value: 'young_18_to_28_years'
            //     },
            //     {
            //         label: 'Acima de 29 anos',
            //         value: 'adult_over_29_years'
            //     }
            // ]
        },
        {
            label: 'Público atendido com deficiência',
            type: 'select',
            value: targetPublic,
            onChange: setTargetPublic,
            formLabel: 'publicServedDisabilities',
            options: [
                {
                    label: 'Baixa visão',
                    value: 'visual_low_vision'
                },
                {
                    label: 'Cegueira',
                    value: 'visual_blind'
                },
                {
                    label: 'Surdo-cegueira',
                    value: 'deafblindness'
                },
                {
                    label: 'Deficiência física',
                    value: 'physics'
                },
                {
                    label: 'Deficiência intelectual',
                    value: 'intellectual'
                },
                {
                    label: 'Deficiência auditiva',
                    value: 'hearing_or_deafness'
                },
                {
                    label: 'Múltiplas deficiências',
                    value: 'multiple'
                },
                {
                    label: 'Não atendo público com deficiência',
                    value: 'i_don_t_serve_disabilities'
                }
            ]
        },
        {
            label: 'Faixa etária para recebimento dos livros',
            // type: 'select',
            // value: ageGroup,
            // onChange: setAgeGroup,
            type: 'list-checkbox',
            value: attendendPublic.map((item) => ({ name: item })),
            formLabel: 'publicByAge',
            items: [
                {
                    value: attendendPublic.indexOf('Até 11 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('Até 11 anos') > -1 ?
                            prevState.filter((item) => item !== 'Até 11 anos') :
                            prevState.concat(['Até 11 anos'])),
                    label: 'Até 11 anos'
                },
                {
                    value: attendendPublic.indexOf('De 12 a 17 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('De 12 a 17 anos') > -1 ?
                            prevState.filter((item) => item !== 'De 12 a 17 anos') :
                            prevState.concat(['De 12 a 17 anos'])),
                    label: 'De 12 a 17 anos'
                },
                {
                    value: attendendPublic.indexOf('De 18 a 28 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('De 18 a 28 anos') > -1 ?
                            prevState.filter((item) => item !== 'De 18 a 28 anos') :
                            prevState.concat(['De 18 a 28 anos'])),
                    label: 'De 18 a 28 anos'
                },
                {
                    value: attendendPublic.indexOf('Acima de 29 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('Acima de 29 anos') > -1 ?
                            prevState.filter((item) => item !== 'Acima de 29 anos') :
                            prevState.concat(['Acima de 29 anos'])),
                    label: 'Acima de 29 anos'
                },
            ]
        },
        {
            type: 'none'
        },
        {
            type: 'text-list',
            label: 'Quantidade de pessoas com deficiência atendidas por faixa etária',
            filter: (value) => `${value}`.replace(NUMBER_ONLY, ""),
            optional: true,
            items: [
                {
                    label: 'Criança (0 a 11 anos e 11 meses)',
                    value: kidsQuantity,
                    onChange: setKidsQuantity,
                    formLabel: 'disabledPeopleQtyKid'
                },
                {
                    label: 'Adolescente (12 anos e 27 anos e 11 meses)',
                    value: teenQuantity,
                    onChange: setTeenQuantity,
                    formLabel: 'disabledPeopleQtyAdolescent'
                },
                {
                    label: 'Jovem (18 anos e 28 anos e 11 meses)',
                    value: youngAdoutQuantity,
                    onChange: setYoungAdultQuantity,
                    formLabel: 'disabledPeopleQtyYoung'
                },
                {
                    label: 'Adulto (acima de 29 anos)',
                    value: adultQuantity,
                    onChange: setAdultQuantity,
                    formLabel: 'disabledPeopleQtyAdult'
                }
            ]
        },
        {
            type: 'list-checkbox',
            label: 'Formatos de livros acessíveis que deseja receber',
            optional: true,
            items: [
                {
                    value: useBraille,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useBraille'
                    },
                    onChange: setUseBraille,
                    label: 'Braille'
                },
                {
                    value: useAudio,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useAudiobooks'
                    },
                    onChange: setUseAudio,
                    label: 'Audiolivro'
                },
                {
                    value: useEnlarged,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useEnlargedFonts'
                    },
                    onChange: setUseEnlarged,
                    label: 'Fonte larga'
                },
                {
                    value: useBrailleInks,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useBrailleInks'
                    },
                    onChange: setUseBrailleInks,
                    label: 'Tinta braille'
                },
                {
                    value: useDaisy,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useDaisy'
                    },
                    onChange: setUseDaisy,
                    label: 'Daisy'
                },
                {
                    value: useEpub,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useEpub3'
                    },
                    onChange: setUseEpub,
                    label: 'ePub3'
                }
            ]
        }
    ]


    const [shareCollection, setShareCollection] = useState(user.accessMyCollection ?? false)

    const finalStepForm = [
        {
            type: 'radio',
            label: 'Para que as pessoas saibam do seu acervo acessível é muito importante a divulgação. Com isso gostaríamos de apoiá-lo divulgando em nossos canais que a sua instituição dispõe de livros acessíveis, informando o telefone, e-mail e endereço.',
            value: shareCollection,
            onChange: setShareCollection,
            formLabel: 'accessMyCollection'
        },
        {
            type: 'none'
        }
    ]

    const handlePublishUserRegistration = () => {

        // const userAccess = {
        //     username: cnpj.replace(NUMBER_ONLY, ""),
        //     email: email,
        //     password: password
        // }
        // console.log("🐌🧲 ~ file: index.jsx ~ line 597 ~ handlePublishUserRegistration ~ userAccess", userAccess)

        const unifiedUserForm = firstStageForm.concat(aboutCollectionForm).concat(passwordForm).concat(finalStepForm)

        const userForm = {
            username: cnpj.replace(NUMBER_ONLY, "")
        }

        unifiedUserForm.forEach(({ value, formLabel, items, image, noForm, filter }) => {
            if (value && formLabel && !image) {
                if (typeof formLabel === 'string') {
                    userForm[formLabel] = filter ? filter(value) : value
                } else {
                    if (!userForm[formLabel.name]) {
                        userForm[formLabel.name] = {}
                    }
                    userForm[formLabel.name][formLabel.entry] = filter ? filter(value) : value
                }
            }

            if (!value && !formLabel && !noForm && items) {
                items.forEach((item) => {
                    if (item.value && item.formLabel) {
                        if (typeof item.formLabel === 'string') {
                            userForm[item.formLabel] = filter ? filter(item.value) : item.value
                        } else {
                            if (!userForm[item.formLabel.name]) {
                                userForm[item.formLabel.name] = {}
                            }
                            userForm[item.formLabel.name][item.formLabel.entry] = filter ? filter(item.value) : item.value
                        }
                    }
                })
            }
        })

        const handleSuccess = () => {
            alert("Alterações salvas com sucesso!")
            history.push('/minha-dorinateca')
        }
        // console.log("🐌🧲 ~ file: index.jsx ~ line 603 ~ handlePublishUserRegistration ~ userForm", JSON.stringify(userForm), userForm)
        handleUserUpdate(userForm, handleSuccess)
    }

    return (
        <>
            <Header />
            <GoBack title="Editar dados (pessoa jurídica)" />
            <Container>
                {
                    step === 1 &&
                    <FormConstructor
                        form={firstStageForm}
                        step={step}
                        proceed={handlePublishUserRegistration}
                        proceedText="Salvar"
                        changeStep={setStep}
                        cancel={() => history.push('/minha-dorinateca')}
                        cancelText="Cancelar"
                        edit={true}
                    />
                }
                {
                    step === 2 &&
                    <FormConstructor
                        form={passwordForm}
                        step={step}
                        proceed={handlePublishUserRegistration}
                        proceedText="Salvar"
                        changeStep={setStep}
                        cancel={() => history.push('/minha-dorinateca')}
                        edit={true}
                        isFromPassword={true}
                    />
                }
                {
                    step === 3 &&
                    <FormConstructor
                        form={aboutCollectionForm}
                        step={step}
                        proceed={handlePublishUserRegistration}
                        proceedText="Avançar"
                        changeStep={setStep}
                        cancel={() => history.push('/minha-dorinateca')}
                        edit={true}
                    />
                }
                {
                    step === 4 &&
                    <FormConstructor
                        form={finalStepForm}
                        step={step}
                        proceed={handlePublishUserRegistration}
                        proceedText="Salvar"
                        changeStep={setStep}
                        cancel={() => history.push('/minha-dorinateca')}
                        edit={true}
                    />
                }
            </Container>
            <Footer />
        </>
    )
}